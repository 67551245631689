import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_OFFER } from '../redux/reducers/offer';
import { SET_USER } from '../redux/reducers/user';
import { trackClick } from '../utils/gaTrackingFunctions';
import { EmailRgx } from '../shared/util/constants';

function LoginModal(props) {
  const isLoggedIn = useSelector((state) => !!state.user.username);
  const siteConfig = props.page;
  const offerBlock = siteConfig.components.find((component) => component.block_type === 'OfferBlock');
  const metaDataBlock = siteConfig.components.find((component) => component.block_type === 'MetaDataBlock');
  const dispatch = useDispatch();
  const {
    offer: { show_login: show, mdid, location, isBulkClip },
    retailerConfig
  } = useSelector((state) => state);  
  const retailer = props.page.dpn_instance ?  props.page.dpn_instance : retailerConfig.dpn_instance;


  const availableOffers = useSelector((state) => state.offer.collections.available);
  const featuredOffers = useSelector((state) => state.offer.collections.featured);
  
  const selectedOffer = availableOffers.find((offer) => offer.mdid === mdid) || 
                        featuredOffers.find((offer) => offer.mdid === mdid);

  let expiration_month, expiration_day, expiration_year;

  if (selectedOffer && selectedOffer.expirationDate) {
    const expiration = new Date(selectedOffer.expirationDate.iso);
    expiration_month = expiration.getMonth() + 1;
    expiration_day = expiration.getDate();
    expiration_year = expiration.getFullYear().toString().substr(2, 4);
  }

  const handleClose = () => {
    setLoginError(false);
    dispatch(SHOW_LOGIN({ show: false }));
  };

  function handleBackgroundClick(e) {
    if (e.target.className.includes('modal')) {
      handleClose();
    }
  }

  let rememberUser = useRef(false);
  let [username, setUserName] = useState();
  let [loginError, setLoginError] = useState('');
  let mpid;
  if (offerBlock.use_test_offers) {
    mpid = '';
  } else {
    mpid = offerBlock.mp_id;
  }
  let funnel = metaDataBlock.funnel;
  let channel = metaDataBlock.channel;

  const defaultColorTheme = {
    primary_color: "#000",
    primary_button_text_color: "#fff",
    secondary_color: "#ccc",
    link_color: "#000",
    text_color: "#000",
    background_color: "#ffffff"
  };

  const colorTheme = siteConfig.components.find((component) => component.block_type === 'ColorTheme') || defaultColorTheme;

  function handleValidation() {
    if (username) {
      let sanitizedUsername = offerBlock.retailer_login_type === 'phone' ? username.replace(/\D/g, '') : username;;

      if(offerBlock.retailer_login_type === "phone" && sanitizedUsername.length < 10) {
        return setLoginError('Please enter a valid 10-digit phone number');
      }

      if(offerBlock.retailer_login_type === "email" && !EmailRgx.test(sanitizedUsername.toLowerCase())) {
        return setLoginError("Please enter a valid email address");
      }
  
      if (isBulkClip) {
        // Clip all available offers
        availableOffers.forEach((offer) => {
          dispatch(
            CLIP_OFFER({
              endpoint: 'offers/load',
              method: 'POST',
              body: {
                id: offer.mdid,
                username: sanitizedUsername,
                mediaPropertyId: mpid,
                channel: channel,
                funnel: funnel,
                location: 'bulk_offer_clip',
              },
              retailer: retailer.dpn_instance
            })
          );
        });

        dispatch(SET_USER({ state: 2, username: sanitizedUsername }));
        if (rememberUser.current.checked) {
          saveToStorage(sanitizedUsername, JSON.stringify(availableOffers[0].mdid));
        }
        trackLoginEvent();
        handleClose();
      } else {
        const options = {
          endpoint: 'offers/load',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-retailer': retailer.dpn_instance,
            Origin: window.location.origin,
          },
          body: {
            username: sanitizedUsername,
            id: mdid,
            mediaPropertyId: mpid,
            channel: channel,
            funnel: funnel,
          },
          retailer: retailer.dpn_instance,
        };

        dispatch(CLIP_OFFER(options)).then((resp) => {
          if (resp.payload.success) {
            dispatch(SET_USER({ state: 2, username: sanitizedUsername }));
            if (rememberUser.current.checked) {
              saveToStorage(sanitizedUsername, JSON.stringify(resp.payload.mdid));
            }
            trackLoginEvent();
            handleClose();
          } else {
            setLoginError(resp.payload.errorMsg);
          }
        });
      }
    } else {
      setLoginError('Member Id required');
    }
  }
  
  function saveToStorage(username, mdid) {
    const sanitizedUsername = offerBlock.retailer_login_type === 'phone' ? username.replace(/\D/g, '') : username;
    const obj = { username: sanitizedUsername, myIds: mdid };
    localStorage.setItem('pbyt', JSON.stringify(obj));
  }

  function RenderLoginError() {
    if (loginError) {
      return <div>{loginError}</div>;
    }
    return '';
  }

  function trackLoginEvent (){
    window.gtag('event', 'account_login', { 
      retailer_id: retailer.dpn_instance,
      version: localStorage.getItem("AB_TEST_VARIABLE") || 'N/A',
    });
  }

  const onInputChangeHandler = (e) => {
    if (offerBlock.retailer_login_type === 'phone') {
      let input = e.target.value.replace(/\D/g, '');
      input = input.slice(0, 10);
      let formattedInput = '';
      for (let i = 0; i < input.length; i++) {
        if (i === 3 || i === 6) {
          formattedInput += '-';
        }
        formattedInput += input[i];
      }
      setUserName(formattedInput);
    } else {
      setUserName(e.target.value);
    }
  }

  if (show) {

    return (
      
      <div className={`modal fade ${show ? 'show' : ''}`} id="loginModal" style={{ display: show ? 'block' : 'none' }} role="dialog" onClick={handleBackgroundClick}>
        <div className="modal-dialog modal-dialog-centered">
        <div className={`tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-z-50 ${show ? 'tw-visible' : 'tw-invisible'}`}>
          <div className="modal-content tw-w-[374px] xl:tw-w-[1200px] tw-p-6 xl:tw-p-16 tw-bg-white tw-rounded-3xl tw-shadow tw-border-0 tw-gap-6 tw-mx-auto" onClick={(e) => e.stopPropagation()}>
            <div className="tw-flex tw-flex-col tw-gap-6 xl:tw-flex-row xl:tw-gap-16">
              <div className='image-section'>
              <button
                type="button"
                className="tw-absolute tw-top-4 tw-right-4 tw-bg-white tw-rounded-md tw-p-2 tw-inline-flex tw-items-center tw-justify-center tw-text-gray-400 hover:tw-text-gray-500 hover:tw-bg-gray-100 focus:tw-outline-none"
                onClick={handleClose}
                aria-label="Close"
              >
                <span className="tw-sr-only">Close menu</span>
                <img src="/images/close_modal.svg" alt="Close" width="12" height="12" />
              </button>
                <div className="tw-self-stretch tw-h-56 xl:tw-h-auto tw-px-6 tw-pt-6 tw-flex-col tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
                  <img className="tw-w-[200px] tw-h-[200px] xl:tw-w-[400px] xl:tw-h-[400px]" src={selectedOffer.enhancedImageUrl} />
                </div>
              </div>
              <div className='content-section xl:tw-w-[504px] tw-space-y-6 xl:tw-flex xl:tw-flex-col xl:tw-justify-center'>
                <div className="tw-text-left tw-space-y-1">
                  <div className="tw-text-zinc-800 tw-text-[21px] xl:tw-text-[31px] tw-font-bold tw-font-['Switzer Variable'] tw-leading-[23.10px] xl:tw-leading-[34.10px]"> {selectedOffer.brand} </div>
                  <div className="tw-self-stretch tw-text-zinc-800 tw-text-base xl:tw-text-lg xl:tw-leading-[28.8px] tw-font-normal tw-font-['Switzer Variable'] tw-leading-relaxed">{selectedOffer.description}</div>
                  <div className="tw-text-gray-500 tw-text-sm tw-font-normal tw-font-['Switzer Variable'] tw-leading-snug">Expires: {expiration_month}/{expiration_day}/{expiration_year} </div>
                </div>
                  
                {!isLoggedIn && (
                  <form className="tw-space-y-3">
                    <div>
                      <div className="tw-w-full tw-h-[58px] tw-p-4 tw-bg-white tw-rounded-lg border tw-border-gray-400 tw-justify-start tw-items-center tw-gap-2 tw-inline-flex">
                        <input
                          type="tel"
                          className="tw-text-zinc-800 tw-font-['Switzer Variable'] tw-outline-none tw-border-none tw-w-full"
                          placeholder={offerBlock.input_placeholder}
                          value={username}
                          onChange={(e) =>
                            onInputChangeHandler(e) 
                          }
                          maxLength={offerBlock.retailer_login_type === 'phone'? 12 : 50}
                          required
                        />
                      </div>
                      <div className="tw-text-left tw-text-gray-500 tw-text-sm tw-font-normal tw-font-['Switzer Variable'] tw-leading-snug tw-mt-1">
                        {/* Enter the 10 digit phone number associated with your Club {retailer.internal_name} account. */
                        }
                        {offerBlock.login_popup_text}
                      </div>
                    </div>

                    <div className="tw-mb-0 tw-text-red-500 tw-text-lg tw-font-normal tw-leading-none">
                      <RenderLoginError />
                    </div>
                  </form>
                )}

                <button
                  className="tw-w-[326px] xl:tw-w-[504px] tw-h-[58px] tw-bg-black tw-rounded-[200px] tw-border tw-border-gray-400 tw-justify-center tw-items-center tw-inline-flex tw-text-center tw-text-white tw-text-base tw-font-bold tw-font-['Switzer Variable'] tw-leading-relaxed tw-outline-none"
                  style={{
                    backgroundColor: selectedOffer.clipped ? 'gray' : colorTheme.primary_color,
                    color: colorTheme.primary_button_text_color,
                  }}
                  onClick={handleValidation}
                >
                  {selectedOffer.clipped ? 'Clipped' : 'Clip Coupon'}
                </button>

      
                <div className="tw-flex tw-flex-col tw-gap-1">
                  {!isLoggedIn && (
                    <div className="tw-flex tw-items-center">
                      <div className="tw-text-zinc-800 xl:tw-text-lg tw-font-normal">
                        {offerBlock.register_account_text?.split('?')[0]}?
                      </div>
                      <div className="tw-text-black xl:tw-text-lg tw-font-bold tw-underline tw-ml-1">
                        <a href={retailer.signup_link} target="_blank" style={{ color: 'black' }}
                          onClick={() => trackClick('retailer_registration', 'popup')}>
                          {offerBlock.register_account_text?.split('?')[1]}
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="tw-text-left tw-text-gray-500 tw-text-sm">Terms & Conditions apply</div>
                </div>
              </div>
              </div> 
              </div>
            </div> 
        </div>
        
        
      </div>
    );
    
  }

  return '';
}
export default LoginModal;
