import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import MetaDataBlock from '../metadata';
import Hero from '../hero';
import CartContent from '../cart';
import Recipes from '../recipes';
import Chatbot from '../chatbot'
import Rebates from '../rebates'
import Spinner from '../common/Spinner';
import ErrorToast from '../common/ErrorToast';
import GardenImages from '../logoGarden';
import TikTokSection from '../tiktok';
import InstagramSection from '../instagram';
import FreeText from '../freeText';
import ContactBlock from '../contact';
import LoyaltyBlock from '../loyalty';
import InformationBanner from '../informationBanner';
import FAQBlock from '../faq';
import DateAgeGate from '../ageGate/date';
import NumberAgeGate from '../ageGate/number';
import CheckboxAgeGate from '../ageGate/checkbox';
import OfferBlock from '../offerBlock'
import '../App.css';
import Navigation from '../navigation';
import Button from '../Button';
import OfferCarousel from '../offerCarousel/offerCarousel';
import LoginModalV2 from '../loginModalV2/loginModalV2';
import SweepstakesEmbed from '../sweepstakesEmbed/sweepstakesEmbed';
import Footer from '../footerV2/footer';
import ProgramExplanation from '../programExplanation/programExplanation';
import ShoppingHistoryContent from '../shoppingHistory';
import { getFullPath } from '../config/retailerConfig';
import { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';

// takes the value of the page data passed in as a prop
function SitePage({page}) {
  // looks for an age gate component. 
  // if there is one, it will lock the site with an age gate until the user confirms their age
  const ageGate = page.components.find((component) => component.block_type === 'AgeGateBlock');
  const [ageGateLocked, setAgeGateLocked] = useState(ageGate ? true : false);
  const [isLoading, setIsLoading] = useState(false)
  
  // finding metadata block for the page
  // IMPORTANT!: BRANDSITES NEED METADATA OR THEY WILL CRASH
  const metaData = page.components.find((component) => component.block_type === 'MetaDataBlock');
  
  // only agegate, header and footer will be shown if age gate is present
  const header = page.components.find((component) => component.block_type === 'HeaderBlock');
  const footer = page.components.find((component) => component.block_type === 'FooterBlock');
  const offerBlock = page.components.find((component) => component.block_type === 'OfferBlock');

  const showLogin = useSelector((state) => state.offer.show_login);

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const siteConfig = useContext(SiteConfigContext);

  const siteBackgroundColor = siteConfig[0].site_color_background;
  const pageBackgroundColor = page.site_color_background;
  const backgroundColor = pageBackgroundColor
  ? pageBackgroundColor
  : siteBackgroundColor
  ? siteBackgroundColor
  : '#fff';

  console.log("siteBackgroundColor: ", siteBackgroundColor)
  console.log("pageBackgroundColor: ", pageBackgroundColor)


  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  function ModalBackdrop() {
    return showLogin ? <div className="modal-backdrop fade show"></div> : '';
  }

  const appClasses = `App ${showLogin ? 'modal-open' : ''}`;

  const updateAgeGate = () => {
    setAgeGateLocked(!ageGate);
  };

  const updateLoadingState = () => {
    setIsLoading(!isLoading)
  }

  if (isLoading) {
    return <Spinner />;
  
    // IMPORTANT! If the live_date for the brandsite is set to a future date, 
    // only the header and footer will show
  } else if (new Date(page.live_date) > new Date()) {
    if (page.slug !== '' && page.out_of_range_redirect != null) {
      let redirectPath = page.out_of_range_redirect === '' ? '/' : `/${page.out_of_range_redirect}`;
      window.location.href = getFullPath(redirectPath);
      return null;
    }
    return (
      <>
        {metaData && <MetaDataBlock page={page}/>}
        {page.coming_soon_desktop_image ? (
          <img 
            src={isMobile ? page.coming_soon_mobile_image : page.coming_soon_desktop_image}
            style={{ width: '100%' }} alt="Coming soon" 
          />
        ) : (
          <h1 style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#242424',
            color: '#e3e3e3',
            fontWeight: 700,
            fontFamily: 'system-ui',
          }}>Coming Soon</h1>
        )}
        {footer && <Footer page={page}/>}
      </>
    );
  } else if (new Date(page.end_date) < new Date()) {
    if (page.slug !== '' && page.out_of_range_redirect != null) {
      let redirectPath = page.out_of_range_redirect === '' ? '/' : `/${page.out_of_range_redirect}`;
      window.location.href = getFullPath(redirectPath);
      return null;
    }
    return (
      <>
        {metaData && <MetaDataBlock page={page}/>}
        {page.program_end_desktop_image ? (
          <img 
            src={isMobile ? page.program_end_mobile_image : page.program_end_desktop_image}
            style={{ width: '100%' }} alt="Program ended"
          />
        ) : (
          <h1 style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            backgroundColor: '#242424',
            color: '#e3e3e3',
            fontWeight: 700,
            fontFamily: 'system-ui',
          }}>Program Ended</h1>
        )}
        {footer && <Footer page={page}/>}
      </>
    );
  }
  // Logic for locked age gate
  // else if(ageGateLocked) {
  //   return(
  //     <>
  //       {metaData && <MetaDataBlock page={page} />}
  //       {header && <Hero page={page}/>}
  //       <StateGate 
  //         // title={stateGate.title}
  //         // instructions={stateGate.instructions}
  //         title={ageGate.title}
  //         instructions={ageGate.instructions}

  //       />
  //     </>
  //   )
  // }
  
  // Logic for locked age gate
  else if (ageGateLocked) {

    // displaying different age gate values for different input_type field values
    switch (ageGate.input_type) {
      case 'Number':
        return (
          <>
            {metaData && <MetaDataBlock page={page} />}
            {header && <Hero page={page}/>}
            <NumberAgeGate
            page={page}
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      case 'Date':
        return (
          <>
            {metaData && <MetaDataBlock page={page} />}
            {header && <Hero page={page}/>}            
            <DateAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
              page={page}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      case 'Boolean':
        return (
          <>
            {header && <Hero page={page}/>}
            <CheckboxAgeGate
              ageGateLocked={ageGateLocked}
              updateAgeGate={updateAgeGate}
              title={ageGate.title}
              instructions={ageGate.instructions}
              instructions2={ageGate.instructions_2}
              minimum_age={ageGate.minimum_age}
              page={page}
            />
            {footer && <Footer page={page}/>}
          </>
        );
      default:
        return null;
    }
  } 
  // if there is no age gate and the site is set to be live, 
  // the other components display in order based on their order in components list from backend response
  else {
    const renderComponent = (component) => {
      switch (component.block_type) {
        case 'Rebates':
          return <Rebates page={page} />;
        case 'ContactBlock':
          return <ContactBlock page={page} />;
        case 'LoyaltyBlock':
          return (
            <>
              {/* loading tesseract before it is needed to avoid time delay when it is used */}
              <LoyaltyBlock page={page} />
            </>
          );
        case 'FAQBlock':
          return <FAQBlock component={component} />;
        case 'LogoGardenBlock':
          return <GardenImages component={component} />;
        case 'MetaDataBlock':
          return <MetaDataBlock page={page} />;
        case 'QualifyingProductsBlock':
          return <InformationBanner page={page} />;
        case 'TikTokBlock':
          return <TikTokSection component={component} />;
        case 'HeaderBlock':
          return <Hero page={page} component={component} />;
        case 'OfferBlock':
          return <OfferBlock updateLoadingState={updateLoadingState} page={page} />;
        case 'AddToCartBlock':
          return <CartContent component={component} />;
        case 'RecipeBlock':
          return <Recipes page={page} />;
        case 'InstagramBlock':
          return <InstagramSection component={component} />;
        case 'FreeTextBlock':
          return <FreeText component={component} />;
        case 'ChatbotBlock':
          return <Chatbot page={page} />;
        case 'ChatbotButtonBlock':
          return <Button component={component} />;
        case 'OfferCarouselBlock':
          return <OfferCarousel component={component} page={page} />;
        case 'SweepstakesBlock':
          return <SweepstakesEmbed component={component} />;
        case 'ProgramExplanationBlock':
          return <ProgramExplanation page={page} />;
        case 'ShoppingHistoryBlock':
          return <ShoppingHistoryContent component={component} />;
        default:
          return null;
      }
    };

    return (
      <div
        className={('App ', appClasses)}
        id="App"
        style={{
          backgroundColor: backgroundColor,
          color: page.colorText,
          fontFamily: page.font_family
        }}
      >
        {offerBlock && <LoginModalV2 page={page} />}
        <div className={`App-content container`} id="App-content">
          <div id="fb-root"></div>
          <div id="fb-customer-chat" className="fb-customerchat"></div>
          <div>
            <Navigation page={page} />
            {Array.isArray(page.components) &&
              page.components.map((component, index) => (
                <div key={index}>{renderComponent(component)}</div>
              ))}
            {page.free_text_blocks &&
              page.free_text_blocks.map((freeTextBlock, index) => (
                <FreeText key={index} freeTextBlock={freeTextBlock} />
              ))}
          </div>
          
          <ErrorToast page={page} />
        </div>
        <ModalBackdrop />
        {footer && <Footer page={page}/>}
      </div>
      
    );
  }
}

export default SitePage;
