import React from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export function PopUpModal(props) {
  const {
    open,
    popUpInfoIcon,
    popUpText,
    popUpTitle,
    colourButtonName,
    whiteButtonName,
    mainColor,
    closeModal,
    colourButtonAction,
    whiteButtonAction
  } = props;

  const useStyles = makeStyles((theme) => ({
    modalDialog: {
      width: "400px",
      height: "auto",
      borderRadius: "16px",
      [theme.breakpoints.down(441)]: {
        width: "-webkit-fill-available",
      },
    },
    modalHeader: {
      display: "flex",
      alignItems: "center",
    },
    modalTitle: {
      marginLeft: "8px",
    },
    modalText: {
      fontFamily: "Switzer",
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: "27px",
      color: "#6B7280",
      width: "352px",
      [theme.breakpoints.down(441)]: {
        width: "-webkit-fill-available",
      },
    },
    modalbutton: {
      width: "172px",
      height: "52px",
      border: "1px",
      padding: "16px 48px",
      fontFamily: "Switzer",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "20px",
      [theme.breakpoints.down(441)]: {
        width: "100%",
      },
    },
    colorButton: {
      [theme.breakpoints.down(441)]: {
        marginBottom: "10px",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "352px",
      [theme.breakpoints.down(441)]: {
        flexDirection: "column-reverse",
        justifySelf: "center",
        width: "-webkit-fill-available",
      },
    },
  }))

  const classes = useStyles();

  const ColorButton = withStyles((theme) => ({
    root: {
      fontSize: "18px",
      lineHeight: "27px",
      color: "white",
      outline: "none",
      backgroundColor: mainColor,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      padding: "12px 24px",
      borderRadius: "240px",
      gap: 16,
      fontFamily: "Switzer",
      fontWeight: '700',
      textTransform: "none",
      "&:hover": {
        backgroundColor: mainColor,
        cursor: "default",
      },
      "&:focus": { outline: "none", boxShadow: "none" },
      "&:active": { outline: "none", boxShadow: "none" },
    },
  }))(Button);

  const WhiteButton = withStyles((theme) => ({
    root: {
      fontSize: "16px",
      lineHeight: "1",
      color: mainColor,
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: mainColor,
      boxShadow: "none",
      borderRadius: "240px",
      gap: 16,
      fontFamily: "Switzer",
      fontWeight: '700',
      textTransform: "none",
      "&:hover": { backgroundColor: "#f2f2f2" },
      padding: "12px 24px",
      "&:focus": { outline: "none", boxShadow: "none" },
      "&:active": { outline: "none", boxShadow: "none" },
    },
  }))(Button);

  return (
    <Modal open={open} onClose={closeModal} showCloseIcon={true} 
    center style={{ borderRadius: '10px', width: "400px" }} 
    classNames={{ modal: classes.modalDialog}}
    styles={{
      closeIcon: {
        fill: '#000', // Change icon color
        borderRadius: '240px',
        backgroundColor: '#EDEEF1',
        width: '24px',
        height: '24px',
      },
      Button: {
        '&:hover': {
          borderRadius: '240px',
          backgroundColor: '#EDEEF1',
          width: '24px',
          height: '24px',
        },
      }
    }}
    >
      <div className="v2-modal">
        <h6 className={classes.modalHeader}> 
          <span className={classes.popUpTitle}>
            {popUpInfoIcon}
          </span>
          <span className={classes.modalTitle}>
          {popUpTitle}
          </span>
        </h6>

        <p className={classes.modalText}
            style={{ textAlign: "start" }}
            dangerouslySetInnerHTML={{ __html: popUpText }}
          />

        <div
          className="form-container-footer layout-spread layout-spread-buttons modal-logout-buttons">
          <div 
            className={classes.buttonContainer}
          >
            <WhiteButton variant="contained" onClick={closeModal} className={classes.modalbutton}>
              {whiteButtonName}
            </WhiteButton>
            <ColorButton variant="contained" onClick={colourButtonAction} className={`${classes.modalbutton} ${classes.colorButton}`}>
              {colourButtonName}
            </ColorButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default PopUpModal;
