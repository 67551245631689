import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, useMediaQuery, createTheme } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { AccountCircleOutlined, BorderColor } from "@material-ui/icons";
import accountActions from "../redux/actions/accountActions";
import { ReactComponent as BarIcon } from "../assets/images/svg/bars.svg";
import { ReactComponent as CloseIcon } from "../assets/images/svg/close.svg";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { formatLoyaltyPoints } from "../shared/util/loyaltyHelper";
import { getFullPath } from "../config/retailerConfig";

function HeaderNavigation(props) {
  const siteConfig = props.page;

  const headerConfig = siteConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const [state, setState] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { pathname } = location;
  const currentPath = pathname.split("/")[2];
  const [currentTab, setCurrentTab] = useState(currentPath);
  const user = useSelector((state) => state.user);
  const [userInfo, setUserInfo] = useState(user?.userDetails);
  const token = localStorage.getItem("token");
  const pointsUnit = siteConfig.loyaltyPoints_display ?? "POINTS";

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const navigationTabs = token
    ? headerConfig.navigationlinks_set
    : headerConfig.navigationlinks_set.filter((p) => !p.is_auth_link);

  useEffect(() => {
    const cPath = pathname.split("/")[2];
    setCurrentTab(cPath);
  }, [pathname]);

  useEffect(() => {
    if (!user.points) {
      dispatch(accountActions.updateUserLytPoints(siteConfig.dpn_instance));
    }
    setUserInfo(JSON.parse(localStorage.getItem("userData")));
  }, []);

  const useStyles = makeStyles((theme) => ({
    navigationCtn: {
      display: "flex",
      justifyContent: "space-between",
      padding: "32px 64px",
      background: headerConfig.background,
      alignItems: "center",
      position: "sticky",
      top: "0",
      zIndex: "999",
      boxShadow: "0px 1px 2px 0px rgba(107, 114, 128, 0.12), 0px 1px 3px 0px rgba(107, 114, 128, 0.06)",

      [theme.breakpoints.down("md")]: {
        padding: "12px 16px",
      },
    },
    logo: {
      width: headerConfig.logo_width,
      maxHeight: "64px",

      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    rightSideActions: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",

      "& svg": {
        fill: headerConfig.text_color,
      }
    },
    navLinks: {
      display: "flex",
      alignItems: "center",

      "& a:hover": {
        textDecoration: "none",
      },

      "& .active": {
        whiteSpace: "nowrap",
        textDecoration: "underline",
        textUnderlineOffset: "34px",
        textDecorationColor: headerConfig.primary_color,
        textDecorationThickness: "4px",
        whiteSpace: "nowrap",

        [theme.breakpoints.down("md")]: {
          textUnderlineOffset: "16px",
        },
      },
    },
    navLink: {
      fontFamily: "switzer",
      textTransform: "capitalize",
      color: headerConfig.text_color,
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "27px",
      margin: "0 24px",
      cursor: "pointer",
      opacity: 1,

      "&:focus": {
        outline: "0",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: '14px',
      },
    },
    accountInfo: {
      alignItems: "center",
      cursor: "pointer",
      color: headerConfig.text_color,
      display: "flex",

      "& .accInfo": {
        marginLeft: "10px",
      },
    },
    mobNavHeader: {
      bottom: "0",
      top: "auto",
      display: "none",

      // Hide header nav links in < 960px
      [theme.breakpoints.down("sm")]: {
        display: "initial",
      },
    },
    btn: {
      background: "transparent",
      padding: "16px 32px",
      borderRadius: "240px",
      borderColor: headerConfig.primary_color,
      fontSize: "18px",
      fontWeight: "700",
      lineHeight: "19.8px",
      textWrap: "nowrap",
      textTransform: "capitalize",

      "&:hover": {
        opacity: ".8",
      },

      [theme.breakpoints.down("md")]: {
        fontSize: '14px',
        padding: "12px 16px",
        marginRight: "12px",
      },
    },
    btnGroup: {
      display: "flex",
      justifyContent: "space-around",

      ".loginBtn": {
        marginRight: "20px",
      },
    },
    mobNavLink: {
      fontFamily: "switzer",
      textTransform: "capitalize",
      color: headerConfig.text_color,
      fontWeight: "700",
      fontSize: "18px",
      lineHeight: "19.8px",
      padding: "20px 0",
      cursor: "pointer",
      opacity: 1,

      "&:hover": {
        textDecoration: "none",
        opacity: ".8",
      },

      "&:focus": {
        outline: "0",
      },
    },
    mobileNavCtn: {
      width: "100%",
      minWidth: "393px",
      minHeight: "100vh",
      margin: "0",
      display: "flex",
      flexDirection: "column",
      background: headerConfig.background,

      "& .headerSec": {
        minHeight: '59px',
        display: 'flex',
        padding: "16px",
        justifyContent: "flex-end",
        alignItems: "center",

        "& svg": {
          height: "21px",
          width: "21px",
          fill: headerConfig.text_color,
        }
      },

      "& .navLinksSec": {
        flex: "1",
        padding: "20px 32px",

        "& .active": {
          textDecoration: "underline",
          textUnderlineOffset: "14px",
          textDecorationColor: headerConfig.primary_color,
          textDecorationThickness: "4px",
        },

        "& a:hover": {
          textDecoration: "none",
        }        
      },

      "& .footerSection": {
        padding: "20px 32px",

        "& .btn": {
          fontSize: '16px',
        }
      }
    },
  }));
  const classes = useStyles();

  const toggleDrawer = (open) => {
    setState(open);
  };

  const onNavLinkHandler = (slug) => {
    setCurrentTab(slug);
    toggleDrawer(false);

    if (typeof window.gtag !== 'undefined') {
      window.gtag("event", "click_navigation", {
        navigation_component: slug,
        version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
      });
    } 
  }

  return (
    <>
      <div className={classes.navigationCtn}>
        <div>
          <a onClick={() => navigate(getFullPath(""))}>
            <img
              className={classes.logo}
              src={
                headerConfig.header_logo
                  ? headerConfig.header_logo
                  : siteConfig.background_image
              }
              alt="logo"
            />
          </a>
        </div>

        <div className={classes.rightSideActions}>
          {!isMobile && <div className={classes.navLinks}>
            {navigationTabs.map((tab, idx) => (
              <Link
                value={currentTab}
                active={currentTab === tab.slug}
                onClick={ () => { 
                  onNavLinkHandler(tab.slug)
                }}
                to={tab.slug.includes('www') ? tab.slug : `/${siteConfig.brandsite_name}/${tab.slug}`}
                target={tab.slug.includes('www') ? '_blank' : '_self'}
                >
                <div
                  className={
                    classes.navLink +
                    `${currentTab === tab.slug ? " active" : ""}`
                  }
                  id={`tab-${idx}`}
                >
                  {tab.navigation_name}{" "}
                </div>
              </Link>
            ))}

            <div>
              {/* MyLoyalty - Show user name along with the points
               */}
              {token && (
                <>
                {/* TODO: Ramesh Yala */}
                {/* {siteConfig.site_type === "MyLoyalty" && <div
                  variant="contained"
                  onClick={() =>
                    navigate(`/${siteConfig.brandsite_name}/account`)
                  }
                  className={classes.accountInfo}
                >
                  <div style={{ display: "flex" }}>
                    <AccountCircleOutlined
                      className={classes.icon}
                      fontSize="large"
                      aria-label="Go to Account page"
                    />
                  </div>
                  <div style={{ fontFamily: "InterBold" }}>
                    <div className="accInfo">
                      Hi,
                      {userInfo !== undefined && userInfo !== null
                        ? userInfo?.firstName
                        : user?.userDetails?.firstName}
                    </div>
                    <div className="accInfo">
                      {formatLoyaltyPoints(user.points, pointsUnit)}
                    </div>
                  </div>
                </div>} */}

                
                <div className={classes.btnGroup}>
                  <button
                    style={{
                      color: headerConfig.primary_color,
                      backgroundColor: "#fff",
                      marginRight: "24px",
                    }}
                    variant="contained"
                    onClick={() => {
                      localStorage.clear();
                      navigate(getFullPath("login"))
                    }}
                    className={classes.btn}
                  >
                    <div data-testid="loginBtn" className="signBtn">
                      Log out
                    </div>
                  </button>
                </div>
                </>
              )}

              {/* Desktop - Show login and Signup */}
              {!token && (
                <div className={classes.btnGroup}>
                  <button
                    style={{
                      color: headerConfig.primary_color,
                      backgroundColor: "#fff",
                      marginRight: headerConfig.show_signup ? "24px" : 0,
                    }}
                    variant="contained"
                    onClick={() =>
                      navigate(getFullPath("login"))
                    }
                    className={classes.btn}
                  >
                    <div data-testid="loginBtn" className="signBtn">
                      Log In
                    </div>
                  </button>

                  {headerConfig.show_signup && <button
                    style={{
                      color: "#fff",
                      backgroundColor: headerConfig.primary_color,
                    }}
                    variant="contained"
                    onClick={() =>
                      navigate(getFullPath("signup"))
                    }
                    className={classes.btn}
                  >
                    <div data-testid="signBtn" className="signBtn">
                      {headerConfig.signup_btn_text}
                    </div>
                  </button>}
                </div>
              )}
            </div>
          </div>
          }

          {/* Mobile View */}
          {isMobile &&  <div>
            <div onClick={() => toggleDrawer(true)}>
              <BarIcon style={{width: '21px', height: '21px'}}/>
            </div>
            <Drawer
              anchor="right"
              open={state}
              onClose={() => toggleDrawer(false)}
              className="mobNavigationDrawer"
            >
              <div className={classes.mobileNavCtn}>
                <div className="headerSec">
                  <CloseIcon onClick={() => toggleDrawer(false)}/>
                </div>

                <div className="navLinksSec">
                  {navigationTabs.map((tab, idx) => (
                    <Link
                      value={currentTab}
                      active={currentTab === tab.slug}
                      onClick={ () => { 
                        onNavLinkHandler(tab.slug)
                      }}
                      to={tab.slug.includes('www') ? tab.slug : `/${siteConfig.brandsite_name}/${tab.slug}`}
                      target={tab.slug.includes('www') ? '_blank' : '_self'}
                    >
                      <div
                        className={
                          classes.mobNavLink +
                          `${currentTab === tab.slug ? " active" : ""}`
                        }
                        id={`tab-${idx}`}
                      >
                        {tab.navigation_name}{" "}
                      </div>
                    </Link>
                  ))}
                </div>

                <div className="footerSection">
                  {token && (
                    <div className={classes.btnGroup}>
                      <button
                        style={{
                          color: headerConfig.primary_color,
                          backgroundColor: "#fff",
                          marginRight: "0",
                          padding: "12px 54px",
                          justifyContent: "space-between",
                          width: "100%",
                        }}
                        variant="contained"
                        onClick={() => {
                          localStorage.clear();
                          navigate(getFullPath("login"))
                          }
                        }
                        className={`${classes.btn} mobBtn`}
                      >
                        <div data-testid="loginBtn" className="signBtn">
                          Log out
                        </div>
                      </button>
                     </div> 
                    )}
                  
                    {!token && (
                    <div className={classes.btnGroup}  style={{
                      justifyContent: "space-between",
                    }}>
                      <button
                        style={{
                          color: headerConfig.primary_color,
                          backgroundColor: "#fff",
                          marginRight: "0px",
                          padding: "12px 54px",
                        }}
                        variant="contained"
                        onClick={() =>
                          navigate(getFullPath("login"))
                        }
                        className={`${classes.btn} mobBtn`}
                      >
                        <div data-testid="loginBtn" className="signBtn">
                          Log In
                        </div>
                      </button>

                      <button
                        className={`${classes.btn} mobBtn`}
                        style={{
                          color: "#fff",
                          backgroundColor: headerConfig.primary_color,
                          padding: "12px 54px",
                          marginRight: "0px",
                        }}
                        variant="contained"
                        onClick={() =>
                          navigate(getFullPath("signup"))
                        }
                      >
                        <div data-testid="signBtn" className="signBtn">
                          {headerConfig.signup_btn_text}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Drawer>
          </div>}
        </div>
      </div>
    </>
  );
}

export default HeaderNavigation;
