import React, { useEffect } from 'react';
import { useContext } from 'react';
import { SiteConfigContext } from '../SiteConfigContext';
import { getPageBySlug } from '../shared/util';

function MetaDataBlock(props) {
  let pageData = props.page;
  const metaBlock = pageData.components.find(component => component.block_type === 'MetaDataBlock');
  const siteConfig = useContext(SiteConfigContext);

  let pageObject = getPageBySlug(siteConfig, pageData.slug);
  if (!pageObject) {
    const altSlug = pageData.slug.startsWith('/') ? pageData.slug.slice(1) : `/${pageData.slug}`;
    pageObject = getPageBySlug(siteConfig, altSlug);
  }

  useEffect(() => {
    // Only run in preview mode
    if (process.env.REACT_APP_PREVIEW_MODE !== 'true') {
      console.log("preview mode false, return")
      return;
    }
    if (!document.querySelector(`script[src*="${metaBlock.ga}"]`)) {

      addCustomScripts(metaBlock.custom_scripts);

      function googleAnalytics() {
        const gaId = metaBlock.ga;
        const galink = document.createElement('script');
        galink.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
        const galink2 = document.createElement('script');
        galink2.innerHTML = `
          window.dataLayer = window.dataLayer || [];
          function gtag() { dataLayer.push(arguments); }
          gtag('js', new Date());
          gtag('config', '${gaId}', {
            'version': '${localStorage.getItem("AB_TEST_VARIABLE")}',
            'brandsite_name':'${siteConfig[0].brandsite_name}',
            'site_client': '${siteConfig[0].client.name}',
            'site_DPN_issuer': '${siteConfig[0].client.dpn_issuer}',
            'page_client': '${pageObject.client.name}',
            'page_DPN_issuer': '${pageObject.client.dpn_issuer}'
          });
        `;

        if (gaId) {
          document.head.appendChild(galink);
          document.head.appendChild(galink2);
        } else {
          console.log('gaId not found');
        }
      }

      function inmarAnalytics() {
        const iaId = metaBlock.inmar_analytics;
        const ialink = document.createElement('script');
        ialink.innerHTML = `
          window._oiqq = window._oiqq || [];
          _oiqq.push(['oiq_addPageLifecycle', '${iaId}']);
          _oiqq.push(['oiq_doTag']);
          (function() {
            const oiq = document.createElement('script');
            oiq.type = 'text/javascript';
            oiq.async = true;
            oiq.src = 'https://px.owneriq.net/stas/s/br53cg.js';
            const s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(oiq, s);
          })();
        `;

        if (iaId) {
          document.head.appendChild(ialink);
        }
      }

      function addMetaData() {
        const head = document.head;

        const keywordsMeta = document.createElement('meta');
        keywordsMeta.setAttribute('name', 'keywords');
        keywordsMeta.setAttribute('content', metaBlock.keywords);
        head.insertBefore(keywordsMeta, head.firstChild);

        const title = document.createElement('meta');
        title.setAttribute('property', 'og:title');
        title.setAttribute('content', metaBlock.og_title);
        head.appendChild(title);

        const url = document.createElement('meta');
        url.setAttribute('property', 'og:URL');
        url.setAttribute('content', metaBlock.og_url);
        head.appendChild(url);

        const description = document.createElement('meta');
        description.setAttribute('name', 'description');
        description.setAttribute('property', 'og:description');
        description.setAttribute('content', metaBlock.og_description);
        head.appendChild(description);

        const image = document.createElement('meta');
        image.setAttribute('property', 'og:image');
        image.setAttribute('content', metaBlock.og_image);
        head.appendChild(image);

        const themeColor = document.createElement('meta');
        themeColor.setAttribute('name', 'theme-color');
        themeColor.setAttribute('content', metaBlock.color_theme);
        head.appendChild(themeColor);

        const fbDomainVerification = document.createElement('meta');
        fbDomainVerification.setAttribute('name', 'facebook-domain-verification');
        fbDomainVerification.setAttribute('content', metaBlock.fb_domain_verification);
        head.appendChild(fbDomainVerification);

        const titleTag = document.createElement('title');
        titleTag.innerHTML = metaBlock.og_title;
        head.appendChild(titleTag);
      }

      function facebookPixel() {
        const fbPixel = metaBlock.fb_pixel;
        const fblink = document.createElement('script');
        fblink.innerHTML = `
          !function(f,b,e,v,n,t,s) {
            if(f.fbq) return;
            n=f.fbq=function() {
              n.callMethod ? n.callMethod.apply(n,arguments) : n.queue.push(arguments)
            };
            if(!f._fbq) f._fbq=n;
            n.push=n;
            n.loaded=!0;
            n.version='2.0';
            n.queue=[];
            t=b.createElement(e);
            t.async=!0;
            t.src=v;
            s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)
          }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${fbPixel}');
          fbq('track', 'PageView');
        `;

        const fblink2 = document.createElement('noscript');
        fblink2.innerHTML = `
          <img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${fbPixel}&ev=PageView&noscript=1"/>
        `;

        if (fbPixel) {
          document.head.appendChild(fblink);
          document.head.appendChild(fblink2);
        }
      }

      function facebookValidation() {
        const fbValidation = document.createElement('meta');
        fbValidation.setAttribute('name', 'facebook-domain-verification');
        fbValidation.setAttribute('content', metaBlock.fb_domain_verification);

        if (metaBlock.fb_domain_verification) {
          document.head.appendChild(fbValidation);
        }
      }

      googleAnalytics();
      inmarAnalytics();
      addMetaData();
      facebookPixel();
      facebookValidation();
    }
  }, [metaBlock]);

  return null;
}

function addCustomScripts(customScripts) {
  if (customScripts) {
    try {
      const div = document.createElement('div');
      div.innerHTML = customScripts;
      const scripts = Array.from(div.querySelectorAll('script'));

      scripts.forEach(script => {
        const newScript = document.createElement('script');
        newScript.type = script.type || 'text/javascript';

        Array.from(script.attributes).forEach(attr => {
          newScript.setAttribute(attr.name, attr.value);
        });

        if (script.src) {
          newScript.src = script.src;
        } else {
          newScript.innerHTML = script.innerHTML;
        }

        document.head.appendChild(newScript);
      });
    } catch (error) {
      console.error('Error appending custom scripts:', error);
    }
  }
}

export default MetaDataBlock;
