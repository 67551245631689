import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { shoppingHistory } from "../service/loyaltysync_services";
import "./shoppingHistory.scss";

function createData(receipt, date, purchase_amount) {
  return { receipt, date, purchase_amount };
}

function ShoppingHistoryContent({ page }) {
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const [receiptHistory, setReceiptHistory] = useState([]);
  const shoppingHistoryBlock = page?.components?.find(
    (component) => component.block_type === "ShoppingHistoryBlock"
  );
  const [width, setWidth] = useState(window.innerWidth);
  let shoppingHeader = shoppingHistoryBlock?.header_text;
  let headerTextColor = shoppingHistoryBlock?.header_text_color;
  let anchorId = shoppingHistoryBlock?.anchor_id;

  const isMobile = width <= 768;

  function formatDate(dateString) {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
  }

  function formatPrice(price) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    }).format(price / 100);
  }

  const useStyles = makeStyles((theme) => ({
    contentPage: {
      maxWidth: "1200px",
      margin: "auto",
      padding: "100px 0",
      [theme.breakpoints.down(1201)]: {
        padding: "100px 64px",
      },
      [theme.breakpoints.down(769)]: {
        padding: "40px 16px",
      },
    },
    shoppingTitle: {
      fontWeight: 800,
      fontFamily: "Switzer",
      fontSize: "90px",
      lineHeight: "81px",
      textAlign: "center",
      marginBottom: "0px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "36px",
        marginTop: "0px",
        textAlign: "center",
        letterSpacing: "-0.36px",
      },
    },
    shoppingTitleContainer: {
      marginBottom: "64px",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0px",
      },
    },
    shoppingColTitle: {
      "&.MuiTableCell-root": {
        fontWeight: "700",
        fontFamily: "Switzer",
        fontSize: "16px",
        lineHeight: "24px",
        width: "33%",
      },
    },
    shoppingColText: {
      "&.MuiTableCell-root": {
        fontWeight: "400",
        fontFamily: "Switzer",
        fontSize: "18px",
        lineHeight: "27px",
        width: "33%",
      },
    },
    shoppingMobileTitle: {
      fontWeight: 800,
      fontFamily: "Switzer",
      fontSize: "36px",
      lineHeight: "32px",
      textAlign: "center",
      marginBottom: "24px",
    },
    shoppingMobileTitleContainer: {
      marginBottom: "24px",
    },
    shoppingMobileColTitle: {
      "&.MuiTypography-root": {
        fontWeight: "700",
        fontFamily: "Switzer",
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
    shoppingMobileColText: {
      "&.MuiTypography-root": {
        fontWeight: "400",
        fontFamily: "Switzer",
        fontSize: "14px",
        lineHeight: "21px",
      },
    },
    link: {
      "&:hover": {
        textDecoration: "none",
      },
      "&:active": {
        textDecoration: "none",
      },
      cursor: "text",
    },
    emptyMsg: {
      fontFamily: "switzer",
      fontSize: "18px",
      color: "#25272C",
      display: "flex",
      justifyContent: "center",

      [theme.breakpoints.down("sm")]: {
        marginBottom: "16px",
        fontSize: "16px",
        padding: "16px 20px",
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    shoppingHistory(
      retailerConfig.retailer_dpn_instance.dpn_instance,
      retailerConfig.retailer_dpn_instance.loyalty_program_id
    )
      .then((result) => {
        const receipts = result.items
          .sort(
            (a, b) =>
              new Date(a.activityStatusUpdatedDateTime) -
              new Date(b.activityStatusUpdatedDateTime)
          )
          .map((item, index) =>
            createData(
              `Purchase ${index + 1}`,
              formatDate(item.activityStatusUpdatedDateTime),
              formatPrice(item.pointsCredit)
            )
          );
        setReceiptHistory(receipts);
      })
      .catch((error) => {
        console.error("Error fetching shopping history:", error);
      });
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sortedReceiptHistory = [...receiptHistory].sort((a, b) =>
    b.receipt.localeCompare(a.receipt)
  );

  if (!shouldRenderComponent(page.auth_visibility)) {
    return null;
  }
  return (
    <>
      {
        <div className={classes.contentPage}>
          <div
            className={
              isMobile
                ? classes.shoppingMobileTitleContainer
                : classes.shoppingTitleContainer
            }
          >
            {shoppingHeader && (
              <p
                id={anchorId}
                className={
                  isMobile ? classes.shoppingMobileTitle : classes.shoppingTitle
                }
                style={{ color: headerTextColor }}
              >
                {shoppingHeader}
              </p>
            )}
          </div>

          {sortedReceiptHistory.length > 0 ? (
            <TableContainer component={Paper} elevation={0}>
              {isMobile ? (
                // Mobile View
                <Box sx={{ padding: 2 }}>
                  {sortedReceiptHistory.map((row, index) => (
                    <Box
                      key={row.receipt}
                      sx={{
                        padding: "8px",
                        borderBottom: "1px solid #e0e0e0",
                      }}
                    >
                      <Typography
                        className={classes.shoppingMobileColTitle}
                        variant="h6"
                        align="left"
                        gutterBottom
                      >
                        {row.receipt}
                      </Typography>

                      {/* Row for purchased and date */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Typography
                          className={classes.shoppingMobileColText}
                          variant="body2"
                          align="left"
                          color="textSecondary"
                        >
                          Purchased
                        </Typography>
                        <Typography
                          className={classes.shoppingMobileColText}
                          variant="body1"
                          align="right"
                        >
                          {row.date}
                        </Typography>
                      </Box>

                      {/* Row for valid purchased amount and amount value */}
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className={classes.shoppingMobileColText}
                          variant="body2"
                          align="left"
                          color="textSecondary"
                        >
                          Valid purchase amount
                        </Typography>
                        <Typography
                          className={classes.shoppingMobileColText}
                          variant="body1"
                          align="right"
                        >
                          {row.purchase_amount}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                </Box>
              ) : (
                // Desktop View
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className={classes.shoppingColTitle}
                        align="left"
                      >
                        Receipt number
                      </TableCell>
                      <TableCell
                        className={classes.shoppingColTitle}
                        align="right"
                      >
                        Purchased
                      </TableCell>
                      <TableCell
                        className={classes.shoppingColTitle}
                        align="right"
                      >
                        Valid purchase amount
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody
                    sx={{
                      "& td, & th": { border: 0 },
                    }}
                  >
                    {sortedReceiptHistory.map((row, index) => (
                      <TableRow
                        key={row.receipt}
                        sx={{
                          backgroundColor:
                            index % 2 === 0 ? "#ffffff" : "#f5f5f5",
                        }}
                      >
                        <TableCell
                          className={classes.shoppingColText}
                          align="left"
                        >
                          {row.receipt}
                        </TableCell>
                        <TableCell
                          className={classes.shoppingColText}
                          align="right"
                        >
                          {row.date}
                        </TableCell>
                        <TableCell
                          className={classes.shoppingColText}
                          align="right"
                        >
                          {row.purchase_amount}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          ) : (
            <p className={classes.emptyMsg}>
              You have not made any qualifying purchases yet
            </p>
          )}
        </div>
      }
    </>
  );
}

export default ShoppingHistoryContent;
