import React from 'react';
import store from '../redux';
import { useDispatch, useSelector } from 'react-redux';
import { CLIP_OFFER, CLIP_ERROR, SHOW_LOGIN } from '../redux/reducers/offer';

const config = store.getState().config;

function BulkOfferClip(props) {
  const dispatch = useDispatch();
  const availableOffers = useSelector((state) => state.offer.collections.available);
  const username = useSelector((state) => state.user.username);

  const siteConfig = props.siteConfig;
  const mpid = siteConfig.mpId;
  let funnel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  let channel = siteConfig.channel ? siteConfig.channel : 'n/a';

  const handleClipAllOffers = () => {
    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, isBulkClip: true }));
    } else {
      availableOffers.forEach((offer) => {
        dispatch(
          CLIP_OFFER({
            endpoint: 'offers/load',
            method: 'POST',
            body: {
              id: offer.mdid,
              username: username,
              mediaPropertyId: mpid,
              channel: channel,
              funnel: funnel,
              location: 'bulk_offer_clip',
            },
            retailer: props.retailer.dpn_instance
          })
        );
      });
    }
  };

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-6 tw-mb-6">
      <div className="tw-flex-grow tw-h-[1px] tw-bg-black"></div>
      <button
        className="tw-px-8 tw-py-4 tw-bg-black tw-rounded-[200px] tw-border tw-border-gray-400 tw-flex tw-items-center tw-justify-center tw-gap-2 tw-cursor-pointer tw-transition tw-duration-300 hover:tw-bg-gray-800 focus:tw-outline-none"
        onClick={handleClipAllOffers}
      >
        <div className="tw-text-center tw-text-white tw-text-lg tw-font-bold tw-leading-[28.80px]">
          Clip All Coupons
        </div>
      </button>
      <div className="tw-flex-grow tw-h-[1px] tw-bg-black"></div>
    </div>
  );
}

export default BulkOfferClip;
