import { createSlice } from "@reduxjs/toolkit";
import config from "../../config";

const initialState = {
  ...config,
  retailerConfig: null,
  envConfig: null,
};

const slice = createSlice({
  name: "config",
  initialState,
  reducers: {
    SET_RETAILER_CONFIG: (state, action) => {
      return { ...state, retailerConfig: action.payload };
    },
    SET_ENVIRONMENT_CONFIG: (state, action) => {
      return { ...state, envConfig: config };
    },
    SET_CONFIG_PROPERTY: (state, action) => {
      return { ...state, [action.payload.key]: action.payload.value };
    },
  },
});

export const {
  SET_RETAILER_CONFIG,
  SET_ENVIRONMENT_CONFIG,
  SET_CONFIG_PROPERTY,
} = slice.actions;

export default slice.reducer;