import React, { useEffect, useState } from 'react'
import { makeStyles } from "@material-ui/core/styles";

function SubmissionInformation({ data, result, primaryColor }) {
  const useStyles = makeStyles((theme) => ({
    containerBox: {
      display: 'flex',
      flexDirection:'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '80px 24px 48px 24px',
      [theme.breakpoints.between("xs", "sm")]: {
        margin: '40px 0px 48px 0px',
      },

    },
    title: {
      width:'381px',
      fontFamily:'Oswald',
      fontSize: '31px',
      fontWeight: '500',
      textTransform: 'uppercase',
      color: `${primaryColor || '#002F57'}`,
      lineHeight: 'normal',
      margin: '24px 0px',
    },
    detailContainer: {
      textAlign: 'left',
      alignSelf:'flex-start',
      padding: '0px 24px',
      maxWidth: '700px',
      width:'652px',
      [theme.breakpoints.between("xs", "sm")]: {
        padding: '0px',
        alignSelf:'center',
        width:'auto'
      },
    },
    detailLabel: {
      display: 'inline-block',
      width: '322px',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '123.5%',
      color: `${primaryColor || '#002F57'}`,
      fontFamily:'Roboto',
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: '14px',
        width: '140px',
      },
    },
    detailValue: {
      fontFamily:'Roboto',
      fontWeight: '400',
      fontSize: '20px',
      color: `${primaryColor || '#002F57'}`,
      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: '14px'
      },
    },
    termsTitle: {
      fontFamily:'Roboto',
      fontSize: '25px',
      color: `${primaryColor || '#002F57'}`,
      fontWeight: 700,
      lineHeight: '123.5%',
      margin: '24px 0px 12px 0px',
    },
    termsDescription: {
      fontFamily:'Roboto',
      margin: 0,
      fontWeight: '400',
      fontSize: '16px',
      color: `${primaryColor || '#002F57'}`,
      [theme.breakpoints.down("xs")]: {
        fontSize: '14px'
      },

    },
    subTitle: {
      color: '#1E1B18',
      fontSize: '28px',
      fontWeight: 400,
    },
    ul: {
      listStyleType: 'none',
      margin: 0,
      padding: 0,
    },

  }));
  const classes = useStyles();
  return (
    <div className={classes.containerBox}>
        {result && data.length == 0 && (<p className={classes.title}>{'No Submissions Found'}</p>)}
        {result && data && data.length > 0 && (<p className={classes.title}>{data.length <= 1 ? 'Submission ID' : 'Multiple Submissions Found'}</p>)}
        {result && data && data.length > 0 &&
          data.map((item, index) => {
            return (
              <div className={classes.detailContainer} key={index}>
                <ul className={classes.ul}>
                  <li style={{ marginBottom: '12px' }}>
                    <span className={classes.termsTitle}>Detailed Status </span>
                  </li>
                  <li>
                    <span className={classes.detailLabel}>Submission Number </span>
                    <span className={classes.detailValue}>{item.submissionNumber}</span>
                  </li>
                  <li>
                    <span className={classes.detailLabel}> Submission Title</span>
                    <span className={classes.detailValue}>{item.offerTitle}</span>

                  </li>
                  <li>
                    <span className={classes.detailLabel}>Submission Status </span>
                    <span className={classes.detailValue}>{item.status}</span>

                  </li>
                  <li>
                    <span className={classes.detailLabel}> Offer Code</span>
                    <span className={classes.detailValue}>{item.offerCode}</span>

                  </li>
                  <li>
                    <span className={classes.detailLabel}> Rebate Amount</span>
                    <span className={classes.detailValue}>  ${item.rebateAmount}</span>

                  </li>
                  <li>
                    <span className={classes.detailLabel}>Payment Method</span>
                    <span className={classes.detailValue}> {item.paymentTypeDesc}</span>

                  </li>
                </ul>
                <div>
                  <p className={classes.termsTitle}>Offer Terms and Conditions</p>
                   <p className={classes.termsDescription}>
                    {item.terms}
                   </p>
                </div>
                <hr style={{ margin: '24px 0px' }}></hr>
              </div>
            )
          })
        }
    </div>
  )
}

export default SubmissionInformation