import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getFullPath } from "../config/retailerConfig";

function CPGPage({ page }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (page) {
      navigate(getFullPath("home"))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      Loading...
    </>
  );
}

export default CPGPage;
