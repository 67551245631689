import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import './qualifyingProducts.scss';

function QualifyingProductsBlock({ show, handleClose, page }) {
  const siteConfig = page;
  const block = siteConfig.components.find(component => component.block_type === 'QualifyingProductsBlock');
  const [brandsAndProducts, setBrandsAndProducts] = useState([]);
  const [heading, setHeading] = useState("");

  useEffect(() => {
    if (block) {
      setBrandsAndProducts(block.qualifying_products_content.content);
      setHeading(block.qualifying_products_content.heading);
    }
  }, [block]);

  if (!shouldRenderComponent(block.auth_visibility)) {
    return null;
  }

  return (
    <>
      <link href="https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap" rel="stylesheet" />
      <Modal show={show} onHide={handleClose} scrollable className="qualifying-modal">
        <Modal.Header>
          <Modal.Title>{heading}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body">
          {brandsAndProducts.map((brandObj, index) => (
            <div key={index} className="brand-list">
              <h2>{brandObj.header}</h2>
              <div className="product-list">
                {brandObj.items.map((product, productIndex) => (
                  <div key={productIndex}>
                    <div>{product.name}</div>
                    <div className="upc">{`UPC: ${product.upc}`}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="close-button" onClick={handleClose}>
            CLOSE
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QualifyingProductsBlock;
