import axios from "axios";
import { getBaseUrl } from '../config/retailerConfig';

export const shoppingHistory = async (retailer, loyaltyProgramId, token = null) => {
  try {
    const authToken = token || localStorage.getItem("retailerToken");
    
    const response = await axios.get(
      `${getBaseUrl(retailer)}/user/loyalty/history`,
      {
        headers: {
          "Content-Type": "application/json",
          "x-retailer": retailer,
          "x-auth-token": authToken,
        },
        params: {
            loyaltyProgramId: loyaltyProgramId,
            activityType: 'EARN'
        }
      }
    );
    return response.status === 200
      ? response.data
      : { error: "Unable to retrieve shopping history" };
  } catch (error) {
    return { error: "Unable to retrieve shopping history" };
  }
};