const AB_TEST_VERSION = localStorage.getItem("AB_TEST_VARIABLE") || "N/A";

export function trackClipError(mdid, location, error_message, username) {
  window.gtag("event", "offer_clip_error", {
    offer_id: mdid,
    module_name: location,
    error_code: error_message,
    version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
    retailer_member_id: username,
  });
}

export function trackInfluencerClick(url, post_order, post_title, platform) {
  window.gtag("event", "click", {
    url: url,
    post_order: post_order,
    post_title: post_title,
    platform: platform,
    version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
  });
}

export function trackLoginError(location, error_message) {
  window.gtag("event", "login_error", {
    error_code: error_message,
    version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
  });
}

export function trackClick(name, location) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", "clip", {
      name: name,
      location: location,
      version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
    });
  }
}

export function trackCashbackOfferLoad(offerCode) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", "cashback_offer_load", {
      offer_code: offerCode,
      version: AB_TEST_VERSION,
    });
    console.log(`Tracked cashback_offer_load: ${offerCode}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackCashbackOfferClick(offerCode) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", "cashback_offer_click", {
      offer_code: offerCode,
      version: AB_TEST_VERSION,
    });
    // console.log(`Tracked cashback_offer_click: ${offerCode}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackCashbackReceiptSubmit(offerCode, submissionNumber = null) {
  if (typeof window.gtag !== 'undefined') {
    const eventData = {
      offer_code: offerCode,
      version: AB_TEST_VERSION,
    };
    
    if (submissionNumber !== null) {
      eventData.submission_number = submissionNumber;
    }

    window.gtag("event", "cashback_receipt_submit", eventData);
    // console.log(`Tracked cashback_receipt_submit: ${offerCode}, Submission Number: ${submissionNumber || 'N/A'}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackCashbackReceiptSubmitError(offerCode, error) {
  if (typeof window.gtag !== 'undefined') {
    window.gtag("event", "cashback_receipt_submit_error", {
      offer_code: offerCode,
      error: error,
      version: AB_TEST_VERSION,
    });
    // console.log(`Tracked cashback_receipt_submit_error: ${offerCode}, Error: ${error}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackSignUpClick(linkUrl = null) {
  if (typeof window.gtag !== 'undefined') {
    const url = linkUrl || window.location.href;
    window.gtag("event", "click", {
      link_URL: url,
      version: AB_TEST_VERSION,
    });
    console.log(`Tracked sign up click: ${url}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackContinueClick(linkUrl = null) {
  if (typeof window.gtag !== 'undefined') {
    const url = linkUrl || window.location.href;
    window.gtag("event", "click", {
      link_URL: url,
      version: AB_TEST_VERSION,
    });
    console.log(`Tracked continue click: ${url}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackRetailerRegistrationClick(linkUrl = null) {
  if (typeof window.gtag !== 'undefined') {
    const url = linkUrl || window.location.href;
    window.gtag("event", "click", {
      link_URL: url,
      version: AB_TEST_VERSION,
    });
    console.log(`Tracked retailer registration click: ${url}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}

export function trackLoginLinkClick(linkUrl = null) {
  if (typeof window.gtag !== 'undefined') {
    const url = linkUrl || window.location.href;
    window.gtag("event", "click", {
      link_URL: url,
      version: AB_TEST_VERSION,
    });
    console.log(`Tracked login link click: ${url}`);
  } else {
    console.error("Google Analytics not loaded");
  }
}