import React, { useEffect } from 'react';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

const SweepstakesEmbed = ({ component }) => {
  const sweepstakesBlock = component;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://d1m2uzvk8r2fcn.cloudfront.net/scripts/embed-code/20230927/embed.min.js';
    script.id = 'campaign-embed-code';
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!shouldRenderComponent(sweepstakesBlock.auth_visibility)) {
    return null;
  }

  return (
    <iframe
      allow="autoplay"
      sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-popups-to-escape-sandbox"
      id={sweepstakesBlock.campaign_id}
      className="embed_frame"
      loading="lazy"
      src={`https://m.cmpgn.page/${sweepstakesBlock.campaign_id}?embed=3&script=1`}
      style={{ border: 'none', minWidth: '100%', height: '979px' }}
      allowPaymentRequest
      allowFullScreen
      data-hj-allow-iframe
    ></iframe>
  );
};

export default SweepstakesEmbed;
