import React from 'react'
import { makeStyles } from "@material-ui/core";
import { Grid } from '@material-ui/core';
function ProductCard({ content, secondary_color, primary_color, product_bg_color }) {
    const { items } = content
    const useStyles = makeStyles((theme) => ({
        content: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            marginBottom: '80px',
            [theme.breakpoints.down("sm")]: {
                marginBottom: '24px',
                width: '100%',
            },
        },
        heading: {
            fontSize: '42px',
            fontWeight: 700,
            lineHeight: '110%',
            fontFamily: "Switzer",
            color: primary_color || '#000',
            marginBottom: '24px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '24px',
                lineHeight: '110%',
                backgroundColor: 'transparent',
                marginBottom: '16px',
            },
        },
        cardContainer: {
            display: 'grid',
            gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
            gap: '24px',
            flexWrap: 'wrap',
            [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "max-content",
                gap: '16px',

            },
        },
        card: {
            width: '100%',
            backgroundColor: product_bg_color || '#F8F9FA',
            borderRadius: '8px',
            padding: '16px',
            [theme.breakpoints.down("sm")]: {
                marginBottom: "16px",
                padding: '0px',
                backgroundColor: 'transparent',
            },
        },
        name: {
            color: primary_color || '#25272C',
            fontWeight: 400,
            fontSize: '18px',
            fontFamily: "Switzer",
            lineHeight: '150%',
            margin: '0px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '14px'
            },
        },
        upc: {
            color: secondary_color || '#6B7280',
            fontWeight: 400,
            fontSize: '16px',
            fontFamily: "Switzer",
            lineHeight: '150%',
            margin: '0px',
            [theme.breakpoints.down("sm")]: {
                fontSize: '12px'
            },
        }
    }))
    const classes = useStyles();
    return (
        <Grid className={classes.content}>

            <h4 className={classes.heading} tabIndex={0}>{content.header}</h4>
            {/* card */}
            <Grid className={classes.cardContainer} tabIndex={0}>
                {items.map((item, index) => {
                    return (
                        <Grid item tabIndex={0} key={index} className={classes.card}>
                            <p tabIndex={0} className={classes.name}>
                                {item.name}
                            </p>
                            <p tabIndex={0} className={classes.upc}>
                                UPC: {item.upc}
                            </p>
                        </Grid>
                    )
                })}
            </Grid>

        </Grid>
    )
}

export default ProductCard