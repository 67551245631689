import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "../offerCarousel/offerCarouselStyles.scss";
import "./logoGarden.scss";

function GardenImages({ component }) {
  const pageConfig = component;
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const [width, setWidth] = useState(window.innerWidth);
  const image_margin = component.image_margin;
  const itemClass = `carousel-item-margin-${image_margin}`;
  const padding_size = component.component_padding;

  const gardenStyle = {
    backgroundColor: pageConfig.color_background,
    paddingTop: `${padding_size}px`,
    paddingBottom: `${padding_size}px`,
  };

  const imgStyle = {
    height: pageConfig.image_height,
  };

  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  if (!shouldRenderComponent(pageConfig.auth_visibility)) {
    return null;
  }

  function getImage(item) {
    if (isMobile && item.mobile_image) {
      return item.mobile_image;
    }
    return item.image;
  }

  function renderLinkAddress(item) {
    if (process.env.REACT_APP_PREVIEW_MODE === "true") {
      if (item.link) {
        return `${window.location.origin}/${retailerConfig.brandsite_name}/${item.link}`;
      } else {
        return `${window.location.origin}/${retailerConfig.brandsite_name}`;
      }
    } else {
      if (item.link) {
        return `${window.location.origin}/${item.link}`;
      } else {
        return `${window.location.origin}`;
      }
    }
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 992 },
      items: pageConfig.carousel_desktop ? pageConfig.carousel_desktop : 3,
      slidesToSlide: 1, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 992, min: 576 },
      items: pageConfig.carousel_tablet ? pageConfig.carousel_tablet : 2,
      slidesToSlide: 1, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 576, min: 0 },
      items: pageConfig.carousel_mobile ? pageConfig.carousel_mobile : 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  return (
    <div className="garden-block-ctn">
      {!pageConfig.show_carousel && (
        <div className="garden" style={gardenStyle}>
          {pageConfig.gardenimage_set.map((item, i) => (
            <Link
              to={renderLinkAddress(item)}
              rel="noopener noreferrer"
              key={i}
            >
              <img
                className={`gardenImg`}
                src={getImage(item)}
                alt={item.alt_text || ""}
                style={imgStyle}
              />
            </Link>
          ))}
        </div>
      )}

      {pageConfig.show_carousel &&
        pageConfig.gardenimage_set.length > 1 && (
          <div className="carousel-ctn" style={gardenStyle}>
            <Carousel
              responsive={responsive}
              autoPlay={pageConfig.auto_scroll}
              infinite={pageConfig.auto_scroll}
              arrows={pageConfig.show_arrows}
              showDots={pageConfig.show_dots}
              rtl={pageConfig.scroll_direction}
              pauseOnHover
              autoPlaySpeed={pageConfig.auto_scroll ? pageConfig.scroll_speed : 0}
              customTransition={pageConfig.auto_scroll ? `all ${pageConfig.scroll_speed}s linear` : 'none'}
              transitionDuration={pageConfig.auto_scroll ? `${pageConfig.scroll_speed}000` : '0'}
              itemClass={pageConfig.continuous_scroll ? "" : itemClass}
              >
              {pageConfig.gardenimage_set.map((item, i) => (
                <img
                  key={i}
                  className={`gardenImg`}
                  src={getImage(item)}
                  alt={item.alt_text || ""}
                  style={imgStyle}
                />
              ))}
            </Carousel>
          </div>
        )}
    </div>
  );
}

export default GardenImages;
