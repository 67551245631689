import React, { useEffect, useState } from "react";
import { usePapaParse } from 'react-papaparse';
import { Grid, makeStyles } from "@material-ui/core";
import SearchBar from "../shared/util/components/searchComponent";
import ProductCard from "./ProductCard";


function ParticipatingProductsBlock({ page }) {
  const [productData, setProductData] = useState([])
  const [rawData, setRawData] = useState([])
  const [textFieldValue, setTextFieldValue] = useState("");
  const { readRemoteFile } = usePapaParse();
  const { components } = page
  const ParticipatingProducts = components.filter((item) => item.block_type === "ParticipatingProductsBlock")
  const { primary_color, secondary_color, product_bg_color, title } = ParticipatingProducts[0]
  const handleReadRemoteFile = () => {
    readRemoteFile(ParticipatingProducts[0].upload_csv_file, {
      complete: (results) => {
        const jsonData = handleCsvToJson(results.data, true)
        setProductData(jsonData)
        setRawData(results.data)
      },
    });
  };

  const handleCsvToJson = (data, val) => {
    //handling csv to json conversion for rendering
    if (val) { data.shift() }
    let title = ""
    const obj = {}
    let dataFinal = []
    data.map((item) => {
      const arr = item
      if (item.length > 1) {
        if (title === "") {
          title = arr[0]
          obj[title] = []
          obj[title].push({
            "name": arr[1],
            "upc": arr[2]
          })
        }
        else if (arr[0] !== title) {
          title = arr[0]
          obj[title] = []
          obj[title].push({
            "name": arr[1],
            "upc": arr[2]
          })
        }
        else {
          obj[title].push({
            "name": arr[1],
            "upc": arr[2]
          })
        }
      }
    })
    const keysObj = Object.keys(obj)
    keysObj.map((item) => {
      dataFinal.push({ "header": item, "items": obj[item] })
    })
    return dataFinal
  }

  useEffect(() => {
    handleReadRemoteFile()
  }, [])

  useEffect(() => {
    // runs on empty search field
    if (textFieldValue === "") {
      handleReadRemoteFile()
    }
  }, [textFieldValue])

  function filterByKeyword(data, keyword) {
    // filter function for search
    const regex = new RegExp(`\\b${keyword}\\b`, 'i'); // Match whole word, case insensitive
    return data.filter(item =>
      item.some(value => regex.test(value))
    );
  }


  const onSearchHandler = () => {
    // search filter

    const keyword = document.getElementById('use-autocomplete').value
    const filteredResults = filterByKeyword(rawData, keyword)
    // 2 way binding through state for filtered data

    // converting to json for rendering
    const jsonDataUpdated = handleCsvToJson(filteredResults, false)
    // rendering updated json converted data
    setProductData(jsonDataUpdated)
    // adding google event
    window.gtag("event", "Search_Participating_Products", {
      text: keyword,
      version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
    });
  }
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '64px',
      maxWidth: '1200px',
      margin: '0 auto',
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
        padding: '16px',
        overflowX: 'hidden',
      },

    },
    header: {
      fontSize: '90px',
      fontWeight: '800',
      fontFamily: "Switzer",
      color: primary_color || '#25272C',
      lineHeight: '90%',
      marginTop: '64px',
      textAlign: 'center',
      [theme.breakpoints.down("sm")]: {
        fontSize: '36px',
        marginTop: '40px',
        textAlign: 'center'
      },
    },
    searchBar: {
      display: 'flex',
      marginBottom: "2rem",
      borderRadius: "8px",
      background: "transparent",
      width: '90% !important',
      height: "54px !important",
      border: '1px solid #B6BAC3',
      margin: '64px 0px !important',
      color: '#6B7280',
      padding: '16px',
      [theme.breakpoints.down("sm")]: {
        maxWidth: "-webkit-fill-available",
        width: '100%!important',
        height: "44px !important",
        margin: '32px 0px !important',
      },

      "& .svgIcon": {
        fill: primary_color || '#25272C' + '!important',
        fontSize: '32px',
      },
    },
    heading: {
      fontSize: '42px',
      fontWeight: 700,
      lineHeight: '110%',
      fontFamily: "Switzer",
      color: primary_color || '#000',
      marginBottom: '24px',
      [theme.breakpoints.down("sm")]: {
        fontSize: '24px',
        lineHeight: '110%',
        backgroundColor: 'transparent',
        marginBottom: '16px',
      },
    },
  }))
  const classes = useStyles();
  return (
    <Grid className={classes.container}>
      <h2 tabIndex={0} className={classes.header}>{title}</h2>
      <SearchBar
        value={textFieldValue}
        placeholder="Search"
        onChange={(newValue) => {
          setTextFieldValue(newValue)
        }}
        className={classes.searchBar}
        onSearch={() => onSearchHandler()}
      />
      {productData && productData.length > 0 && productData.map((item, index) => {
        return (
          <ProductCard key={index} content={item} primary_color={primary_color} secondary_color={secondary_color} product_bg_color={product_bg_color} />
        )
      })}
      {productData.length < 1 && (<p className={classes.heading}>No Results Found!</p>)}
    </Grid>
  )
}

export default ParticipatingProductsBlock