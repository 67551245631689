import React, { useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

import "./hero.scss";

function Hero(page) {
  const siteConfig = page.page;
  const [width, setWidth] = useState(window.innerWidth);

  const isMobile = width <= 768;

  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function StaticHero() {
    const hero = siteConfig.components.find(
      (component) => component.block_type === "HeaderBlock"
    ).header_set[0];
    return (
      <img
        className="heroImg"
        src={isMobile? hero.mobile_header :  hero.desktop_header}
        alt={hero?.logo_alt_text ? hero?.logo_alt_text : "logo"}
        style={{height: hero.header_heigth ? hero.header_heigth : 'initial' }}
      />
    );
  }

  function HeroCarousel() {
    const heroCarousel = siteConfig.components.find(
      (component) => component.block_type === "HeaderBlock"
    ).header_set;
    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1, // set item per slide to 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 1,
        slidesToSlide: 1, // set item per slide to 1
      },
      mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
        slidesToSlide: 1, // set item per slide to 1
      },
    };
    return (
      <Carousel
        responsive={responsive}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        arrows={false}
        swipeable={true}
      >
        {heroCarousel.map((item, i) => (
          <a href={item.anchor} key={i}>
            <img
              className="heroImg"
              src={isMobile ? item.mobile_header : item.desktop_header}
              alt={item.header_image_alt_text}
              style={{height: item.header_heigth ? item.header_heigth : 'initial' }}
            />
          </a>
        ))}
      </Carousel>
    );
  }

  const hero = siteConfig.components.find(
    (component) => component.block_type === "HeaderBlock"
  );
  if (!shouldRenderComponent(hero.auth_visibility)) {
    return null;
  }

  return (
    <div className="header">
      <div className="hero">
        {hero.header_set.length > 1 && <HeroCarousel />}
        {hero.header_set.length === 1 && <StaticHero />}
      </div>
    </div>
  );
}

export default Hero;
