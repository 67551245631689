import { getBaseUrl } from '../config/retailerConfig';

const convertBase64ToBlob = async (base64) => {
    // converting base64 to blob to send image data
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob;
  };

export async function receiptUpload(capturedImages, retailer){
    // api call to upload the images
    const myHeaders = new Headers();
    const token = window.localStorage.getItem('token')
    myHeaders.append("x-auth-token", token);
    myHeaders.append("x-retailer", retailer);
    myHeaders.append("mimeType", "multipart/form-data");

    const formdata = new FormData();

    for (let i =0;i<capturedImages.length; i ++){
        // converting to blob and append in formdata
        const imageData = await convertBase64ToBlob(capturedImages[i])
        formdata.append("images", imageData, `receipt_${i}.png`);

    }   
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
            };
    const response = await fetch(`${getBaseUrl(retailer, false)}/receipts`, requestOptions)
        .then((response) => response.text())
        .then((result) => result)
        .catch((error) => console.error(error));
        return response
}