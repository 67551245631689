import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

function CapturedImages({ setImageDeleted, imageDelete, capturedImages, selectedImage, currentIndex, handleSetCurrentIndex, desktop, handleCurrentImage }) {

  const useStyles = makeStyles((theme) => ({
    infoText: {
      fontSize: '12px',
      color: '#fff',
      fontWeight: '400',
      position: 'absolute',
      padding: '0px 12px',
      borderRadius: '240px',
      background: 'rgba(0, 0, 0, 0.40)',
      backdropFilter: 'blur(6.400000095367432px)',
    },
  }));
  const classes = useStyles();
  const [current, setCurrent] = useState()
  const handleImage = (item, i) => {
    handleSetCurrentIndex(i)
    selectedImage(item)

  }
  useEffect(() => {
    if (!desktop) {
      if (imageDelete == true) {
        document.getElementsByClassName('capturedPhotos')[0].click()
      }
      setImageDeleted(false)
    }
  }, [imageDelete])
  return (
    <div style={{ position: 'relative', display: 'flex', width: '80%', justifyContent: 'center' }}>
      {current && <p className={classes.infoText}>{current} of {capturedImages.length}</p>}
      <div style={{ display: 'flex', overflow: 'scroll' }}>
        {
          capturedImages && capturedImages.length > 0 &&
          capturedImages.map((item, i) => {
            return (
              <div style={{ margin: '2px', border: `${i === currentIndex ? '2px solid #FFC107' : ''}` }} key={i}>
                {!desktop && <img className="capturedPhotos" alt="Captured Images" width={48} height={48} src={item} onClick={() => handleImage(item, i)} />}
                {desktop && <img alt="Captured Images" style={{ borderRadius: '18%', width: "80px", height: '80px' }} src={item} onClick={() => handleCurrentImage(item, i)} />}
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CapturedImages