import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import SummaryTable from "./SummaryTable";
import MobileView from "./MobileView";
import { getProgramDetials } from "../service/myloyalty_services";
import { convertDate } from "../shared/util/utils";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

function RewardsSummaryBlock({ page, retailerConfig }) {
  const { components } = page;

  const { dpn_instance, loyalty_program_id } =
    retailerConfig.retailer_dpn_instance;
  const rewardsSummary = components.filter(
    (item) => item.block_type === "RewardsSummaryBlock"
  );
  const { wallet_url, auth_visibility, title, anchor_id } = rewardsSummary[0];
  const [rowsData, setRowsData] = useState([]);
  const emptyMsg = "You have not received any rewards yet";

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "64px",
      width: "100%",
      maxWidth: "1200px",
      margin: "auto",
      padding: "100px 0",
      [theme.breakpoints.down(1201)]: {
        padding: "100px 64px",
      },
      [theme.breakpoints.down(769)]: {
        padding: "40px 16px",
      },
      [theme.breakpoints.down("sm")]: {
        overflowX: "hidden",
        gap: "0px",
      },
    },
    header: {
      fontSize: "90px",
      fontWeight: "800",
      fontFamily: "Switzer",
      color: "#25272C",
      lineHeight: "90%",
      marginTop: "64px",
      letterSpacing: "-0.9px",
      gap: "64px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "36px",
        marginTop: "0px",
        marginBottom: "24px",
        textAlign: "center",
        letterSpacing: "-0.36px",
      },
    },
    desktopView: {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    mobileView: {
      display: "none!important",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#fff",
        padding: "16px",
        display: "block !important",
        width: "100%",
      },
    },
    button: {
      display: "flex",
      padding: "16px 48px",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      border: "1px solid #2F9240",
      backgroundColor: "#fff",
      borderRadius: "240px",
      width: "255px",
      height: "auto",
      color: "#2F9240",
      fontSize: "18px",
      fornWeight: "700",
      marginTop: "48px",
      boxShadow:
        "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",
      "&:hover": {
        backgroundColor: "#fff",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        width: "auto",
        padding: "12px 24px",
      },
    },
    emptyMsg: {
        fontFamily: 'switzer',
        fontSize: "18px",
        display: "flex",
        color: "#25272C",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
            marginBottom: "16px",
            fontSize: "16px",
            padding: "0 20px",
        }
      }
  }));
  const classes = useStyles();

  useEffect(() => {
    const rows = [];
    getProgramDetials(
      dpn_instance,
      loyalty_program_id,
      window.localStorage.getItem("retailerToken") || ""
    ).then((data) => {
      const { rewards } = data && data?.rewardGroups[0];
      rewards.map((reward) => {
        const { offer } = reward;
        const expirationDate = offer.expirationDate
          ? convertDate(offer.expirationDate)
          : "- - ";
        if (offer.earnedDates.length > 1 || offer.redeemedDates.length > 1) {
          if (offer.earnedDates.length > 1) {
            const redeemedDate = offer.redeemedDates[0]
              ? convertDate(offer.redeemedDates[0])
              : "- -";
            for (let i = 0; i < offer.earnedDates.length; i++) {
              const earnedDate = convertDate(offer.earnedDates[i]);
              rows.push(
                createData(
                  offer.shortDescription,
                  earnedDate,
                  redeemedDate,
                  expirationDate
                )
              );
            }
          }
          if (offer.redeemedDates.length > 1) {
            const earnedDate = offer.earnedDates[0]
              ? convertDate(offer.earnedDates[0])
              : "- -";
            for (let i = 0; i < offer.redeemedDates.length; i++) {
              const redeemedDate = convertDate(offer.redeemedDates[i]);
              rows.push(
                createData(
                  offer.shortDescription,
                  earnedDate,
                  redeemedDate,
                  expirationDate
                )
              );
            }
          }
        } else {
          const earnedDate = offer.earnedDates[0]
            ? convertDate(offer.earnedDates[0])
            : "- - ";
          const redeemedDate = offer.redeemedDates[0]
            ? convertDate(offer.redeemedDates[0])
            : "- - ";
          rows.push(
            createData(
              offer.shortDescription,
              earnedDate,
              redeemedDate,
              expirationDate
            )
          );
        }
      });
      setRowsData(rows);
    });
  }, []);

  function createData(desc, earnedDate, redeemedDate, expiredDate) {
    return { desc, earnedDate, redeemedDate, expiredDate };
  }
  if (!shouldRenderComponent(auth_visibility)) {
    return null;
  }
  return (
    <div className={classes.container}>
      <h2 id={anchor_id} className={classes.header} tabIndex={0}>
        {title}
      </h2>

      <div className={classes.desktopView}>
        {rowsData.length > 0 ? (
          <SummaryTable tabIndex={0} rowsData={rowsData} />
        ) : (
          <p className={classes.emptyMsg}>{emptyMsg}</p>
        )}
      </div>

      <div className={classes.mobileView}>
        {rowsData.length > 0 ? (
          <>
            {" "}
            {rowsData.map((row, index) => {
              return <MobileView tabIndex={0} key={index} row={row} />;
            })}{" "}
          </>
        ) : (
          <p className={classes.emptyMsg}>{emptyMsg}</p>
        )}
      </div>
      {wallet_url && (
        <button
          className={classes.button}
          onClick={() => window.open(wallet_url)}
        >
          View Inmart Wallet
        </button>
      )}
    </div>
  );
}

export default RewardsSummaryBlock;
