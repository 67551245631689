import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  useMediaQuery,
  createTheme,
  Box,
  Container
} from "@material-ui/core";
import FormWrapper from "../blocks/Account/FormWrapper";
import EnrollmentStepsComponent from "../blocks/Account/EnrollmentSteps";
import { getFullPath } from "../config/retailerConfig";

function SignupWrapper(props) {
  const signupConfig = props.page.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up(1200));

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexDirection: isLargeScreen ? 'row' : 'column',
      gap: theme.spacing(4),
      maxWidth: 1300,
      margin: '0 auto',
      padding: isMobile ? theme.spacing(2) : theme.spacing(4),
    },
    howItWorksContainer: {
      flex: isLargeScreen ? '1 1 50%' : '1 1 auto',
    },
    signupContainer: {
      flex: isLargeScreen ? '1 1 50%' : '1 1 auto',
    },
    paper: {
      maxWidth: 630,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",
    },
    paperMobile: {
      maxWidth: 560,
      margin: `${theme.spacing(1)}px auto`,
      padding: "28px 2px 10px 2px",
      boxShadow: "none",
      margin:0,
      padding:0
    },
    loginLogo: {
      width: "200px",
      height: "auto",
      margin: isMobile ? "1rem" : "1rem 4rem",
      objectFit: "contain",
      marginBottom: 0
    },
    signIntxt: {
      fontSize: isMobile ? "30px" : "60px",
      fontFamily: "Switzer",
      fontWeight: 700,
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "Switzer",
    },
    createTxt: {
      color: `${signupConfig?.primary_color} !important`,
      cursor: "pointer",
      fontFamily: "Switzer",
      fontWeight:'bold',
    },
    container2: {
      margin: 0,
      padding: 0
    }
  }));

  const classes = useStyles();

  
  const navigate = useNavigate();
  const formFields = signupConfig.retailerFields?.formFields;
  const enrollmentSteps = signupConfig.enrollment_steps;
  if (!signupConfig) {
    return <>Loading...</>;
  }
  const signupConfirmationText = signupConfig.signup_confirmation_text
  ? signupConfig.signup_confirmation_text.replaceAll(
      "<CPG name>",
      props.page.dpn_instance.dpn_instance
    )
  : "";

  return (
    <div className={classes.container2}>
      <Grid item xs={12} zeroMinWidth style={{textAlign:signupConfig?.logo_Alignment}}>
        <a onClick={() => navigate(getFullPath(""))}>
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={signupConfig.logo}
          />
        </a>
      </Grid>

      <Container className={classes.container}>
      {props.page.site_type === 'LoyaltySync' && (
        <Box className={classes.howItWorksContainer}>
          <EnrollmentStepsComponent enrollmentSteps={enrollmentSteps} retailerDpnInstance={props.page.retailer_dpn_instance} signupConfig={signupConfig} />
        </Box>
      )}
        
        <Box className={classes.signupContainer}>
          <Paper className={isMobile ? classes.paperMobile : classes.paper}>
            <Grid container wrap="nowrap" spacing={1}>
              <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
                <Typography
                  tabIndex={0}
                  className={classes.signIntxt}
                  noWrap
                  style={{ textAlign: "left", marginBottom: '40px' }}
                  data-testid="signIn"
                >
                  {signupConfig.page_title}
                </Typography>
                {/* <Typography
                  tabIndex={0}
                  className={classes.createAccount}
                  data-testid="donthaveAccount"
                >
                  {signupConfig.signup_sub_titleText}
                </Typography> */}
                <FormWrapper
                  formType="register"
                  config={signupConfig}
                  brandsiteName={props.page.brandsite_name}
                  confirmTxt={signupConfirmationText}
                  fields={formFields}
                  retailerName={props.page.dpn_instance.dpn_instance}
                  loyaltyConnectRetailerName={props.page.retailer_dpn_instance.dpn_instance}
                  loyaltyConnectRetailerAccountName={props.page.retailer_dpn_instance.retailer_account_name}
                  loyaltyConnectRetailerIdType={props.page.retailer_dpn_instance.retailer_id_type}
                  enrollmentType={props.page.site_type}
                  loyaltyProgramId={props.page.retailer_dpn_instance.loyalty_program_id}
                />

                  <Typography
                    tabIndex={0}
                    noWrap
                    data-testid="donthaveAccount"
                    style={{
                      textAlign: 'left',
                      marginTop: isMobile ? '-8px' : '32px',
                      marginBottom: isMobile ? '8px': '',
                      fontFamily: 'Switzer',
                      lineHeight: '150%',
                      fontSize: isMobile ? '16px': '18px'
                    }}
                  >
                  Have an account?
                  <span 
                    data-testid="createAccount"
                    onClick={() => navigate(getFullPath("login"))}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        navigate(getFullPath("login"));
                      }
                    }}
                    style={{
                      color: signupConfig?.primary_color ,
                      cursor: 'pointer',
                      textDecoration: 'none',
                      fontWeight: 700,
                    }}
                    tabIndex={0}
                  >
                    {" "}
                    Log in
                  </span>
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}

export default SignupWrapper;