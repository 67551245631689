import { useSelector } from "react-redux";
import HeaderNavigation from '../blocks/headerNavigation';
import FooterV2 from '../footerV2/footer';
import getComponent from '../shared/util/index';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

function EarnPointsPage({ page }) {
  const retailerConfig = page[0];

  const { isLogin } = useSelector((state) => state.user);

  const pageConfig = page.find(page =>
    page.slug === 'earn_points' &&
    (isLogin
      ? ['both', 'authenticated'].includes(page.auth_visibility)
      : ['both', 'unauthenticated'].includes(page.auth_visibility))
  );

  const header = retailerConfig.components.find((component) => component.block_type === 'HeaderNavigationBlock');
  const footer = retailerConfig.components.find((component) => component.block_type === 'FooterBlock');

  if (!shouldRenderComponent(pageConfig.auth_visibility)) {
    return null;
  }

  return (
    <>
      <>
      {header && <HeaderNavigation page={retailerConfig}/>}

      <div id="App-content">
        {pageConfig && pageConfig.components.map((blockConfig) => (
          <>{getComponent(blockConfig.block_type, pageConfig, blockConfig, retailerConfig)}</>
        ))}
      </div>

      {footer && <FooterV2 page={retailerConfig} />}
    </>
    </>
  );
}

export default EarnPointsPage;