import HeaderNavigation from '../blocks/headerNavigation';
import FooterV2 from '../footerV2/footer';
import getComponent from '../shared/util/index';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';

function RedeemPointsPage({ page }) {
  const retailerConfig = page[0];
  const pageConfig = page.find((page) => page.slug === 'redeem');

  const header = retailerConfig.components.find((component) => component.block_type === 'HeaderNavigationBlock');
  const footer = retailerConfig.components.find((component) => component.block_type === 'FooterBlock');

  if (!shouldRenderComponent(pageConfig.auth_visibility)) {
    return null;
  }

  return (
    <>
      <>
      {header && <HeaderNavigation page={retailerConfig}/>}

      <div id="App-content">
        {pageConfig && pageConfig.components.map((blockConfig) => (
          <>{getComponent(blockConfig.block_type, pageConfig, blockConfig)}</>
        ))}
      </div>

      {footer && <FooterV2 page={retailerConfig} />}
    </>
    </>
  );
}

export default RedeemPointsPage;