import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as ArrowIcon } from "../assets/images/svg/triangle.svg";

function LoyaltyProgress(props) {
  const { offerInfo, color, indicatorColor, height, isLoySyncProgress } = props;
  const { targetBalance, rewardGroups, pointsDisplayUnit } = offerInfo;

  const { targetAchieved } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0].rewards[0]
      : 0;
  const { targetThreshold } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0]
      : 9999;
  const { clipLimitPerShopper } =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? rewardGroups[0].rewards[0].offer
      : 0;
  let completed = targetBalance % targetThreshold;
  const maxCompleted = targetThreshold;
  const reset =
    rewardGroups.length > 0 && rewardGroups[0].rewards.length > 0
      ? props.offerInfo.rewardGroups[0].rewards[0].targetAchieved !==
        props.offerInfo.rewardGroups[0].rewards[0].offer.clipLimitPerShopper
      : true;

  if (targetThreshold === targetBalance && reset) {
    completed = 0;
  }
  let margin = `${(completed / maxCompleted) * 100 - 2}`;
  if (clipLimitPerShopper === targetAchieved) {
    completed = maxCompleted;
    margin = "97";
  }

  const useStyles = makeStyles((theme) => ({
    completedProgress: {
      height: "32px",
      width: (margin < 0 ? 0 : margin) + "%",
      background: color,
      transition: "width 1s ease-in-out",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      animation: "auto ease 0s 1 normal none running none",
    },
    progressBarLabel: {
      fontSize: "14px",
      fontWeight: "600",
      textAlign: "left",
      color: "#25272C",
      padding: "4px 0px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "12px",

      [theme.breakpoints.between("xs", "sm")]: {
        fontSize: "12px",
        padding: "16px 8px",
      },
    },
    tooltip: {
      marginLeft: (margin < 0 ? 0 : margin) + "%",
      padding: "0",
      boarder: "0",
      background: "transparent",
      animation: `$MoveUpDown 1s linear infinite`,
      outline: "none",

      "&:hover": {
        background: "transparent",
      },

      "& button:focus": {
        outline: "none",
      },

      "& .svgIcon": {
        height: "42px",
        width: "28px",
        marginLeft: "2px",
        fill: indicatorColor,
        marginLeft: margin > 1 ? "-15px" : "0",

        [theme.breakpoints.between("xs", "sm")]: {
          width: "23px",
          height: "27px",
          marginLeft: margin > 1 ? "-13px" : "0",
        },
      },
    },
    caret: {
      borderLeft: "16px solid transparent",
      borderRight: "16px solid transparent",
      borderBottom: "32px solid #555",
      content: "",
      position: "absolute",
      top: "100%",
      left: "50%",
      marginLeft: "-5px",
      borderStyle: "solid",
      borderColor: "transparent transparent #f8d27d transparent",
    },
    "@keyframes MoveUpDown": {
      "0%, 100% ": {
        marginTop: "-48px",
      },
      "50%": {
        marginTop: "-42px",
      },
    },
  }));
  const classes = useStyles();
  const displayUnit = pointsDisplayUnit;

  return (
    <div className="progress-ctn">
      {isLoySyncProgress && (
        <div className="bottomRightLabel" style={{ paddinTop: "4px" }}>
          <>
            You are &nbsp;
            {(displayUnit.includes("cents") ||
              displayUnit.includes("dollar")) &&
              "$"}
            {(displayUnit.includes("cents")
              ? (maxCompleted - completed) / 100
              : maxCompleted - completed
            ).toFixed(2)}
            &nbsp;
            {/* <span>
                {(displayUnit.includes("cents") ||
                  displayUnit.includes("dollar")) &&
                  "spent"}
              </span>
              <span>{displayUnit.includes("point") && "earn"} </span> */}
            {/* <span>
              {(displayUnit.includes("item") ||
                displayUnit.includes("purchase")) &&
                "Purchase"}
            </span> */}{" "}
            away from reaching&nbsp;
            <span style={{ fontWeight: "600" }}>
              {displayUnit.includes("cents")
                ? `$${maxCompleted / 100}`
                : `$${maxCompleted}`}
            </span>
          </>
        </div>
      )}

      {isLoySyncProgress && (
        <div style={{ width: "100%", height: "0" }}>
          {" "}
          <button className={classes.tooltip} style={{ width: "max-content" }}>
            <ArrowIcon current={indicatorColor} class="svgIcon" />
          </button>
        </div>
      )}

      <div
        className="progressbar-section"
        style={
          isLoySyncProgress ? { margin: "16px 10px 0 10px", width: "90%" } : {}
        }
      >
        <ProgressBar
          type="button"
          className="muiProgress"
          completedClassName={
            isLoySyncProgress ? classes.completedProgress : null
          }
          customLabel=" "
          completed={completed}
          maxCompleted={maxCompleted}
          height={height ? height : 8}
          bgColor={color}
          baseBgColor="#EDEEF1"
        />
      </div>
      <div className={classes.progressBarLabel + " pgLabel"}>
        <div>
          {/* display complete text */}
          {clipLimitPerShopper === targetAchieved ? (
            <span>Completed</span>
          ) : (
            <>
              {isLoySyncProgress ? (
                <>
                  &nbsp;
                  {(displayUnit.includes("cents") ||
                    displayUnit.includes("dollar")) &&
                    "$"}
                  {(displayUnit.includes("cents")
                    ? completed / 100
                    : maxCompleted - completed
                  ).toFixed(2)}{" "}
                  <span>
                    {(displayUnit.includes("cents") ||
                      displayUnit.includes("dollar")) &&
                      "spent"}
                  </span>
                  <span>{displayUnit.includes("point") && "earn"} </span>
                  <span>
                    {(displayUnit.includes("item") ||
                      displayUnit.includes("purchase")) &&
                      "Purchase"}
                  </span>{" "}
                  towards next reward{" "}
                </>
              ) : (
                <>
                  <span>
                    {(displayUnit.includes("cents") ||
                      displayUnit.includes("dollar")) &&
                      "Spend"}
                  </span>
                  <span>{displayUnit.includes("point") && "earn"} </span>
                  <span>
                    {(displayUnit.includes("item") ||
                      displayUnit.includes("purchase")) &&
                      "Purchase"}
                  </span>
                  &nbsp;
                  {(displayUnit.includes("cents") ||
                    displayUnit.includes("dollar")) &&
                    "$"}
                  {displayUnit.includes("cents")
                    ? (maxCompleted - completed) / 100
                    : maxCompleted - completed}{" "}
                  more {displayUnit.includes("items") && "Items"}
                  {displayUnit.includes("point") && "Points"}
                </>
              )}
            </>
          )}
        </div>

        {/* LoySyncProgreess - Right side label under progress bar (mobile) */}
        {isLoySyncProgress && (
          <div className="rightLabel">
            <>
              You are &nbsp;
              {(displayUnit.includes("cents") ||
                displayUnit.includes("dollar")) &&
                "$"}
              {(displayUnit.includes("cents")
                ? (maxCompleted - completed) / 100
                : maxCompleted - completed
              ).toFixed(2)}{" "}
              {/* <span>
                {(displayUnit.includes("cents") ||
                  displayUnit.includes("dollar")) &&
                  "spend"}
              </span>
              <span>{displayUnit.includes("point") && "earn"} </span> */}
              {/* <span>
                {(displayUnit.includes("item") ||
                  displayUnit.includes("purchase")) &&
                  "Purchase"}
              </span> */}{" "}
              away from reaching{" "}
              <span style={{ fontWeight: "600" }}>
                {displayUnit.includes("cents")
                  ? `$${maxCompleted / 100}`
                  : `$${maxCompleted}`}
              </span>
            </>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoyaltyProgress;
