import axios from 'axios';
import { getBaseUrl } from '../config/retailerConfig';
import accountActions from '../redux/actions/accountActions';
import { enrollProgram } from './myloyalty_services';

export const signup = 
 (
  username, 
  password,
  phone,
  first_name,
  last_name,
  month,
  year,
  address1,
  address2,
  city,
  state,
  zip,
  country,
  retailer
  ) =>
{
  let payloadData = 
  {
    "firstName": first_name,
    "lastName": last_name,
    "email": username,
    "phone":phone,
    "password": password,
    "birthMonth": month,
    "birthYear": year,
    "address1": address1,
    "address2": address2,
    "city": city,
    "state": state,
    "zip": zip,  
}
  if(country){
    // adding to avoid blank country field - 400 bad request
    payloadData.country=country
  }
  return axios.post(
    `${getBaseUrl(retailer)}/user/register`,
    payloadData
      ,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
  );
}

export const login = (username, password, retailer) =>
  axios.post(
    `${getBaseUrl(retailer, true)}/user/login`,
    {
      username,
      password,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
);

export const forgotPassword = (email, retailer, linkHost, linkPath) => {
  const data = { email };
  
  if (linkHost) {
    data.linkHost = linkHost;
  }
  
  if (linkPath) {
    data.linkPath = linkPath;
  }

  return axios.post(
    `${getBaseUrl(retailer, true)}/user/password/forgot`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-retailer': retailer,
      },
    }
  );
};

export const getUserInfo = async (token, retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer,
    'x-auth-token': token,
  };
  try {
    const response = await axios.get(`${getBaseUrl(retailer)}/user`, { headers });
    response.data.token = token;
    return response.data;
  } catch (error) {
    return { error };
  }
};

export const getUserPoints = async (retailer) => {
  const headers = {
    'Content-Type': 'application/json',
    'x-retailer': retailer?.dpn_instance,
    'x-auth-token': localStorage.getItem('token'),
  };

  try {
    const response = await axios.get(`${getBaseUrl(retailer.dpn_instance, true)}/user/cashback/balances`, { headers });
    return response.data;
  } catch (error) {
    return { error };
  }
};
export const changePassword = async (passwordData, retailer) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": localStorage.getItem("token"),
  };
  return axios
    .post(
      `${getBaseUrl(retailer, true)}/user/password`,
      passwordData,
      { headers }
    )
    .then((response) => response.data)
    .catch((error) => {
      if (error.response.data.status == 404) {
        return {
          error: "The current password you entered is incorrect. Please try again",
          fields: error.response?.data?.fields,
          message:"",
        };
      } else {
        return {
         error: "The current password you entered is incorrect. Please try again",
         fields: error.response?.data?.fields,
         message: error.response?.data.message,
        };
      }
    });
};

export const updateUserCall = async (userData, retailer) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
    "x-auth-token": localStorage.getItem("token"),
  };

  return axios
    .post(`${getBaseUrl(retailer)}/user`, userData, { headers })
    .then((response) => {
      if (response.data.status == 404) {
        return {
          error: "Error.userProfileError",
          fields: response.data?.fields,
        };
      }
      if (response.data.status == 401) {
        return {
          error: {
            code: 401,
            status: "Token Expired",
          },
        };
      }
      return response.data;
    })
    .catch((error) => ({
      error: "Error.userProfileError",
      fields: error.response?.data?.fields,
      message: error.response?.data.message,
    }));
};

export const updateUserSuccess = (retailer) => async (dispatch) => {
  const userToken = localStorage.getItem("token");
  const userData = await getUserInfo(localStorage.getItem("token"), retailer);
  if (!userData.error) {
    userData.token = userToken;
    userData.totalsLastUpdate = new Date().toString();
    dispatch(accountActions.setUserDetails(userData))
    localStorage.setItem("userData", JSON.stringify(userData));
  }
};

export const getLoyaltyToken = async (username, retailerName) => {
  const headers = {
    "Content-Type": "application/json",
  };

  try {
    const lambdaResponse = await axios.post(
      process.env.REACT_APP_VALIDATE_USER_LAMBDA,
      {
        action: 'getToken',
        username: username,
        retailer_name: retailerName
      },
      { headers }
    );
    
    if (!lambdaResponse.data.token) {
      throw new Error(`Token not found in lambda response. Response data: ${JSON.stringify(lambdaResponse.data)}`);
    }

    return lambdaResponse.data.token;
  } catch (error) {
    console.error('Error getting loyalty token:', error);
    throw error;
  }
};

export const createUserCall = async (userData, retailer, enrollmentType, loyaltyConnectConfig={}) => {
  const headers = {
    "Content-Type": "application/json",
    "x-retailer": retailer,
  };

  try {
    const response = await axios.post(`${getBaseUrl(retailer)}/user/register`, userData, { headers });

    if (response.data.status === 404 || response.data.status === 400) {
      return {
        error: "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
        fields: response.data.fields || [],
      };
    }

    if (response.data.status === 409) {
      return {
        error: "user already registered.",
        fields: response.data.fields,
      };
    }

    const token = response.data.token;
    localStorage.setItem("token", token);

    if (enrollmentType === 'LoyaltySync' && loyaltyConnectConfig.loyaltyProgramId) {
      try {
        const username = userData.username;
        let loyaltyToken;
        
        try {
          loyaltyToken = await getLoyaltyToken(username, loyaltyConnectConfig.loyaltyConnectRetailerName);
          localStorage.setItem("retailerToken", loyaltyToken);
        
          const enrollResult = await enrollProgram(loyaltyConnectConfig.loyaltyConnectRetailerName, loyaltyConnectConfig.loyaltyProgramId, loyaltyToken);
          if (enrollResult.error) {
            throw new Error(enrollResult.error);
          }
        } catch (error) {
          throw new Error("Unable to complete enrollment. Please try again or contact support.");
        }
    
        const capitalizedRetailer = loyaltyConnectConfig.loyaltyConnectRetailerName.charAt(0).toUpperCase() + loyaltyConnectConfig.loyaltyConnectRetailerName.slice(1);
        const sfmcLambdaUrl = process.env.REACT_APP_SFMC_LAMBDA;
    
        const sfmcLambdaParams1 = {
          action: 'add_to_enroll_list',
          email: userData.email,
          retailer: capitalizedRetailer,
          list_id: loyaltyConnectConfig.sfmcListId
        };
    
        const sfmcResponse1 = await axios.post(sfmcLambdaUrl, sfmcLambdaParams1);
        
        if (sfmcResponse1.data.status_code !== 'OK') {
          console.error('SFMC Lambda call (add_to_enroll_list) was not successful:', sfmcResponse1.data);
          throw new Error('Failed to add user to enrollment list');
        }
    
        const sfmcLambdaParams2 = {
          action: 'trigger_email',
          email: userData.email,
          retailer: capitalizedRetailer,
          customer_key: loyaltyConnectConfig.sfmcWelcomeEmailId
        };
    
        const sfmcResponse2 = await axios.post(sfmcLambdaUrl, sfmcLambdaParams2);    
        if (sfmcResponse2.data.status_code !== 'OK') {
          console.error('SFMC Lambda call (trigger_email) was not successful:', sfmcResponse2.data);
          throw new Error('Failed to trigger welcome email');
        }
    
      } catch (error) {
        console.error(error);
        return {
          error: error.message || "An error occurred during the enrollment process. Please try again or contact support.",
          fields: []
        };
      }
    }

    return token;
  } catch (error) {
    if (error.response && error.response.data.status === 409) {
      return {
        error: "user already registered.",
        fields: error.response.data.fields,
      };
    } 
    else if (error.response && error.response.data.status == 400) {
      return {
        error:
          "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
        fields: error.response.data.fields,
      };
    }
    else if (error.response && error.response.data.status == 404) {
      return {
        error:
          "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
        fields: error.response.data.fields,
      };
    }
    else {
      return {
        error: "Your request cannot be completed. Please try again. If it continues to happen, please contact the support team.",
        fields: [],
      };
    }
  }
};

export const createUser = (data, retailer, enrollmentType, loyaltyConnectConfig) => async () => {
  const result = await createUserCall(data, retailer, enrollmentType, loyaltyConnectConfig);
  return result;
};