import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  TextareaAutosize,
  makeStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  validateEmailAddress,
  validateStandard,
} from "../shared/util/UserValidations";
import EmailIcon from "../assets/images/svg/envelope.svg";
import PhoneIcon from "../assets/images/svg/phone.svg";
import { SiteConfigContext } from "../SiteConfigContext";
import axios from 'axios';
import { getFullPath } from "../config/retailerConfig";


function ContactBlock(props) {
  const contactConfig = props.page.components.find(
    (component) => component.block_type === "ContactBlock"
  );
  const siteConfig = useContext(SiteConfigContext);
  const retailerDpnInstance = siteConfig[0].retailer_dpn_instance;
  

  const { user } = useSelector((state) => state);
  const cachedUser = JSON.parse(localStorage.getItem("userData"));
  
  const isLogin = !!user;

  const [values, setValues] = useState({
    email: "",
    name: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    emailError: "",
    firstNameError: "",
    lastNameError: "",
    messageError: "",
    generalError: "",
  });
  const [validations, setValidations] = useState({
    isLoading: false,
    successMessage: "",
  });
  const navigate = useNavigate();
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 1200,
      marginTop: theme.spacing(3),
      margin: `${theme.spacing(1)}px auto`,
      marginBottom: '24px',
      padding: '100px',
      boxShadow: "0px 20px 40px 0px rgba(107, 114, 128, 0.20)",
      borderRadius: "24px",
      border: "1px solid #B6BAC3",
      fontSize: '18px',
      [theme.breakpoints.down("lg")]: {
        width: "90%",
      },
      [theme.breakpoints.down("xs")]: {
        width: "90%",
        fontSize: '14px',
        padding: theme.spacing(3),
      },
    },
    formInputControl: {
      gap: '4px',
      display: "flex !important",
      margin: "10px 0 !important",

      "& .MuiFormHelperText-root": {
        fontSize: "14px",
        color: "#212931",
        fontWeight: "700",
        marginLeft: 0,
      },
      [theme.breakpoints.only('xs')]: {
        gap: '2px',
      },
    },
    signIntxt: {
      fontWeight: "800",
      marginBottom: "18px",
      fontSize: "90px",
      [theme.breakpoints.only('xs')]: {
        fontSize: "36px",
      },
    },
    alertBoxsuccess: {
      marginTop: '10px',
      color: " #44a047",
      backgroundColor: "rgba(93, 157, 81, 0.2)",
      border: "1px solid #44a047",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      padding: "1.4rem 0.6rem",
      borderRadius: "8px",
      width: "98%",
      margin: "0 auto",
    },
    contactInfo: {
      alignItems: "center",
      display: "flex",
      width: "fit-content",
      margin: "8px 0",
      [theme.breakpoints.only('xs')]: {
        margin: "0",
      },
    },
    avatar: {
      marginTop:'2px',
      marginRight: "16px",
      width: "14px",
      height: "14px",
    },
    contactTag: {
      margin: "0 !important",
      color: "#25272C",
    },
    textField: {
      fontSize: "18px",
      marginBottom: "12px",
      "&>div": {
        fontSize: "18px",
        borderRadius: "10px",
      },
      "& input": {
        padding: '19px'
      },
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        "&>div": {
          fontSize: "14px",
        },
        "& input": {
          fontSize: "14px",
          padding: "12px",
        }
      },
    },
    textareaField: {
      overflow: "hidden",
      minHeight: "160px",
      borderRadius: "10px",
      paddingLeft: "12px",
      fontSize: "18px",
      borderColor: "rgba(0, 0, 0, 0.23)",
      padding: '16px',
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        fontSize: "14px",
        paddingTop: "8px",
        height: '160px',
        padding: ''
      },
    },
    errortextareafield: {
      width: "29em",
      minHeight: "120px",
      overflow: "hidden",
      background: "#FBEAE7",
      paddingLeft: "12px",
      fontSize: "16px",
      fontWeight: "400",
      color: "#D82B0C",
      fontStyle: "normal",
      borderRadius: "10px",
      borderColor: "#f44336",
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    errortextfield: {
      width: "29em",
      background: "#FBEAE7",
      fontSize: "16px",
      "& .MuiFormHelperText-root": {
        fontSize: "14px",
        fontWeight: "400",
        color: "#D82B0C",
        fontStyle: "normal",
        borderRadius: "4px",
      },
      "&>div": {
        fontSize: "16px",
        height: "48px",
        borderRadius: "4px",
      },
      "& input": {
        padding: "14.5px 14px",
      },
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    errorMessage: {
      fontSize: "14px !important",
      fontWeight: "400 !important",
      color: "#D82B0C !important",
      fontStyle: "normal !important",
      marginBottom: "5px",
    },
    submitBtn: {
      color: contactConfig?.button_color,
      outline: "none",
      marginTop: '4px',
      padding: "16px",
      lineHeight: '110%',
      fontSize: "18px",
      fontWeight: 700,
      fontFamily: "Switzer",
      width: "150px",
      maxWidth: "156px",
      boxShadow: "none",
      borderRadius: "30px",
      backgroundColor: contactConfig?.background_color,
      textTransform: "capitalize",
      boxShadow: "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",

      "&:hover": {
        boxShadow: "none",
        backgroundColor: contactConfig?.background_color,
      },
      "&:focus": {
        boxShadow: "none",
        backgroundColor: contactConfig?.background_color,
        outline: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
        maxWidth: "100%",
        padding: "12px 24px",
        fontSize: "14px"
      },
    },
    faqLink: {
      color: (props) => props.contactConfig?.background_color,
      textDecoration: 'none',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    gridContainer: {
      [theme.breakpoints.up('md')]: {
        '& > .MuiGrid-item:first-child': {
          paddingRight: '32px',
        },
        '& > .MuiGrid-item:last-child': {
          paddingLeft: '32px',
        },
      },
    },
    customH6: {
      fontSize: '18px',
      fontWeight: 700,
      [theme.breakpoints.down("xs")]: {
        fontSize: '14px',
        margin: '0',
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set user values after login
    setValidations({ ...validations, isLoading: true });
    const cachedUser = JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))
      : user;
    setValues({
      ...values,
      email: cachedUser?.email,
      firstName: cachedUser?.firstName,
      lastName: cachedUser?.lastName
    });
    setValidations({ ...validations, isLoading: false });
  }, [user]);

  // input filed values assigning
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    setErrors({ ...errors, [`${prop}Error`]: "" });
    setValidations({ ...validations, successMessage: "" });
  };

  const handleFormFieldclass = (fieldValue) => {
    if (fieldValue === "messageError") {
      return errors[fieldValue] ? classes.errortextareafield : classes.textareaField;
    }
    
    const field = fieldValue.replace('Error', '');
    const hasError = errors[fieldValue] && values[field] !== "";
    return hasError ? classes.errortextfield : classes.textField;
  };

  const handleForgot = async () => {
    if (window.gtag) {
      window.gtag("event", "customer_care_form_submit", {
        contact_Submit: "Customer Care form submitted",
      });
    }
    setValidations({ ...validations, isLoading: true });
    const emailErr = validateEmailAddress(values.email, "forgot");
    const firstNameErr = values.firstName.trim() === "" ? "Field must not be blank" : "";
    const lastNameErr = values.lastName.trim() === "" ? "Field must not be blank" : "";
    const messageErr = validateStandard(values.message);
  
    if (emailErr === "" && firstNameErr === "" && lastNameErr === "" && messageErr === "") {
      
      const lambdaPayload = {
        action: "trigger_customer_care",
        email: values.email,
        retailer: retailerDpnInstance?.internal_name || "",
        first_name: values.firstName,
        last_name: values.lastName,
        shopper_customer_key: contactConfig?.shopper_customer_key || "",
        contact_customer_key: contactConfig?.contact_customer_key || "",
        content: values.message,
        brandsite_url: contactConfig?.brandsite_url || "",
        customer_care_email: contactConfig?.customer_care_email || "",
        program_id: retailerDpnInstance?.loyalty_program_id || "",
        member_id: isLogin ? (user?.phone || cachedUser?.phone || "") : "",
      };
    
      try {
        const response = await axios.post(
          process.env.REACT_APP_SFMC_LAMBDA,
          lambdaPayload
        );
        setValidations({ isLoading: false, successMessage: "Your message has been sent successfully." });
      } catch (error) {
        console.error("Error calling Lambda:", error);
        setValidations({ isLoading: false, successMessage: "" });
        setErrors({ ...errors, generalError: "An error occurred while submitting the form. Please try again." });
      }
    } else {
      setErrors({
        emailError: emailErr,
        firstNameError: firstNameErr,
        lastNameError: lastNameErr,
        messageError: messageErr,
      });
      setValidations({ ...validations, isLoading: false });
    }
  };

  if (!contactConfig) {
    return <>Loading...</>;
  }
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={2}
        style={{  }}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <>
            <h1
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
              style={{ color: contactConfig.title_color }}
            >
              {contactConfig.title}
            </h1>
            <p style={{ color: "#25272C" }}>
              For questions about retailer registration or your digital rewards,
              please review {props?.retailerConfig?.dpn_instance?.dpn_instance}{" "}
              <a
                style={{ color: contactConfig?.background_color }}
                onClick={() => navigate(getFullPath("faq"))}
                className={classes.faqLink}
              >
                FAQs.
              </a>
            </p>
            <p style={{ color: "#25272C" }}>
              For additional {props?.retailerConfig?.dpn_instance?.dpn_instance}{" "}
              related questions, please contact:
            </p>
              <h6 className={classes.customH6}>
                <span style={{ textTransform: "capitalize" }}>
                  {props?.retailerConfig?.dpn_instance?.dpn_instance}
                </span>
                {" customer care"}
              </h6>
              <div className={classes.contactInfo}>
                <img className={classes.avatar} src={EmailIcon} />
                <p className={classes.contactTag}>{contactConfig.email}</p>
              </div>
              <div className={classes.contactInfo}>
                <img className={classes.avatar} src={PhoneIcon} />
                <p className={classes.contactTag}>{contactConfig.phone}</p>
              </div>
          </>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <>
            <FormControl
              className={classes.formInputControl}
              variant="outlined"
            >
              <TextField
                id="FirstName"
                variant="outlined"
                error={errors.firstNameError !== "" && values.firstName !== ""}
                value={values.firstName}
                onChange={handleChange("firstName")}
                placeholder="First Name"
                className={handleFormFieldclass("firstNameError")}
              />
              <TextField
                id="LastName"
                variant="outlined"
                error={errors.lastNameError !== "" && values.lastName !== ""}
                value={values.lastName}
                onChange={handleChange("lastName")}
                placeholder="Last Name"
                className={handleFormFieldclass("lastNameError")}
              />
              {(errors.firstNameError !== "" || errors.lastNameError !== "") && (
                <FormHelperText
                  className={classes.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {errors.firstNameError || errors.lastNameError}
                </FormHelperText>
              )}
              <TextField
                id="Email"
                variant="outlined"
                error={errors.emailError != ""}
                placeholder="Email"
                value={values.email}
                onChange={handleChange("email")}
                className={handleFormFieldclass("emailError")}
              />
              {errors.emailError !== "" && (
                <FormHelperText
                  className={classes.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {errors.emailError}
                </FormHelperText>
              )}
              <TextareaAutosize
                maxRows={45}
                id="Text"
                variant="outlined"
                error={errors.messageError != ""}
                placeholder="Message"
                value={values.text}
                onChange={handleChange("message")}
                className={handleFormFieldclass("messageError")}
              />
              {errors.messageError !== "" && (
                <FormHelperText
                  className={classes.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {errors.messageError}
                </FormHelperText>
              )}
            </FormControl>

            <Button
              variant="contained"
              onClick={handleForgot}
              disabled={validations.isLoading}
              className={classes.submitBtn}
            >
              <div data-testid="signBtn" className="signBtn">
                {/* {contactConfig.submit_btn_text}
                 */}
                Submit
              </div>
            </Button>
            {validations.successMessage !== "" &&
              validations.successMessage !== undefined && (
                <div className={classes.alertBoxsuccess}>
                  Your message has been sent successfully.
                </div>
              )}
          </>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default ContactBlock;