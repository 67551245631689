import React from "react";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core";

export function LogoutModal(props) {
  const { open, mainColor, mainColorHover, closeModal, logout } = props;

  const useStyles = makeStyles((theme) => ({
    modalDialog: {
      borderRadius: "8px",
      padding: "16px",
    },
  }))

  const classes = useStyles();

  const ColorButton = withStyles((theme) => ({
    root: {
      width: "172px",
      height: "52px",
      fontSize: "18px",
      lineHeight: "20px",
      color: "white",
      outline: "none",
      backgroundColor: mainColor,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      padding: "16px 48px",
      border: "1px solid",
      borderRadius: "240px",
      gap: 16,
      fontFamily: "Switzer",
      fontWeight: '700',
      textTransform: "none",
      "&:hover": {
        backgroundColor: mainColorHover,
        cursor: "default",
      },
      "&:focus": { outline: "none", boxShadow: "none" },
      "&:active": { outline: "none", boxShadow: "none" },
    },
  }))(Button);

  const WhiteButton = withStyles((theme) => ({
    root: {
      width: "172px",
      height: "52px",
      fontSize: "18px",
      lineHeight: "20px",
      color: mainColor,
      backgroundColor: "transparent",
      border: "1px solid",
      borderColor: mainColor,
      boxShadow: "none",
      borderRadius: "240px",
      gap: 16,
      fontFamily: "Switzer",
      fontWeight: '700',
      textTransform: "none",
      "&:hover": { backgroundColor: "#f2f2f2" },
      padding: "16px 48px",
      "&:focus": { outline: "none", boxShadow: "none" },
      "&:active": { outline: "none", boxShadow: "none" },
    },
  }))(Button);

  return (
    <Modal open={open} onClose={closeModal} showCloseIcon={false}  center style={{borderRadius:'16px'}} classNames={{ modal: classes.modalDialog }}>
      <div className="v2-modal">
        <p>Are you sure you want to logout of your account?</p>

        <div 
        className="form-container-footer layout-spread layout-spread-buttons modal-logout-buttons">
          <div 
            style={{
              display: "flex",
              justifyContent:" space-between"
            }}
          >
            <WhiteButton variant="contained" onClick={closeModal}>
              Cancel
            </WhiteButton>
            <ColorButton variant="contained" onClick={logout}>
              Logout
            </ColorButton>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default LogoutModal;
