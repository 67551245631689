import React from 'react'
import { makeStyles, Typography } from "@material-ui/core";
import Box from '@mui/material/Box';

function MobileView({row}) {
  return (
    <Box tabIndex={0}  sx={{ width: '100%', paddingBottom: '16px', borderBottom: "1px solid #B6BAC3", marginBottom:'16px'}}>

    {/* Row for Reward */}
    <Box tabIndex={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', marginBottom:'8px'}}>
        <Typography variant="body2" align="left" color="textSecondary" style={{fontFamily:'Switzer', lineHeight:'21px'}} >
            Reward
        </Typography>
        <Typography variant="body1" align="right" style={{fontFamily:'Switzer', lineHeight:'21px', alignItems: 'baseline'}}>
            {row.desc}
        </Typography>
    </Box>

    {/* Row for earned */}
    <Box tabIndex={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'8px' }}>
        <Typography variant="body2" align="left" color="textSecondary" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
            Earned
        </Typography>
        <Typography variant="body1" align="right" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
            {row.earnedDate}
        </Typography>
    </Box>

    {/* Row for redeemed */}
    <Box tabIndex={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'8px'}}>
        <Typography variant="body2" align="left" color="textSecondary" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
            Redeemed
        </Typography>
        <Typography variant="body1" align="right" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
        {row.redeemedDate}
        </Typography>
    </Box>

    {/* Row for Expired */}
    <Box tabIndex={0} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom:'8px' }}>
        <Typography variant="body2" align="left" color="textSecondary" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
            Expires
        </Typography>
        <Typography variant="body1" align="right" style={{fontFamily:'Switzer', lineHeight:'21px'}}>
        {row.expiredDate}
        </Typography>
    </Box>
</Box>
  )
}

export default MobileView