import React, { useState, useMemo } from 'react';
import { Box, Typography, IconButton, Stack, ThemeProvider, Collapse, Container, useMediaQuery, createTheme } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as ArrowUpRightIcon } from '../../../src/assets/images/svg/ArrowUpRight.svg';

const EnrollmentStepsComponent = ({ enrollmentSteps, retailerDpnInstance, signupConfig }) => {
  const enrollmentStepsTheme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: signupConfig.primary_color,
      },
      background: {
        default: '#F8F9FA',
        paper: '#FFFFFF',
      },
      text: {
        primary: '#25272C',
        secondary: '#000000',
      },
    },
    typography: {
      fontFamily: 'Switzer, sans-serif',
    },
  }), [signupConfig.primary_color]);

  const [isExpanded, setIsExpanded] = useState(true);
  const isXs = useMediaQuery(enrollmentStepsTheme.breakpoints.only('xs'));
  const isDesktop = useMediaQuery(enrollmentStepsTheme.breakpoints.up('lg'));

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const EnrollmentStepsSection = ({ title, description, number, isDesktop }) => (
    <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
      {isDesktop && (
        <Box sx={{ 
          bgcolor: 'primary.main', 
          borderRadius: '50%',
          minWidth: 28,
          width: 28,
          height: 28,
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          flexShrink: 0,
        }}>
          <Typography sx={{ color: 'background.paper', fontSize: 16, fontWeight: 700 }}>{number}</Typography>
        </Box>
      )}
      <Box>
        <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 'bold', color: 'text.primary' }} gutterBottom>
          {title}
        </Typography>
        <Typography 
          variant="body2" 
          sx={{ 
            color: 'text.secondary',
            fontSize: { xs: '16px', lg: '18px' }
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );

  return (
    <ThemeProvider theme={enrollmentStepsTheme}>
      <Box
        sx={{
          width: { xs: '100vw', sm: '100%' },
          marginLeft: { xs: 'calc(-50vw + 50%)', sm: 0 },
          minHeight: { lg: 986 },
          bgcolor: 'background.default',
          display: 'flex',
          justifyContent: 'center',
          borderRadius: { xs: 0, lg: '24px' },
        }}
      >
        <Container 
          disableGutters={isXs}
          maxWidth={false}
          sx={{
            maxWidth: {
              xs: '100%',
              sm: '100%',
              md: '100%',
              lg: '1000px'
            },
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              px: { xs: 2, sm: 3, md: 4, lg: 4 },
              pt: { xs: 3, sm: 4, md: 5, lg: 8 },
              pb: isExpanded 
                ? { xs: 3, sm: 4, md: 5, lg: 8 }
                : { xs: 1, sm: 0, md: 5, lg: 8 },
              display: 'flex',
              flexDirection: 'column',
              alignItems: { lg: 'flex-start' },
              gap: { xs: 4, sm: 5, md: 6, lg: 4 },
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  mb: { xs: 2, lg: 8 },
                  pb: { xs: 3 }
                }}
              >
                <Typography
                  sx={{
                    color: 'text.primary',
                    fontWeight: 'bold',
                    fontSize: { xs: '30px', sm: '30px', md: '30px', lg: '52px' },
                    lineHeight: { xs: '33px', lg: '57px' },
                  }}
                >
                  {enrollmentSteps.headingText}
                </Typography>
                {!isDesktop && (
                  <IconButton
                    onClick={toggleExpand}
                    sx={{
                      color: 'primary.main',
                      transform: `rotate(${isExpanded ? 180 : 0}deg)`,
                      transition: 'transform 0.3s',
                    }}
                  >
                    <ExpandMoreIcon fontSize="large" />
                  </IconButton>
                )}
              </Box>

              <Collapse sx={{ mb: 2 }} in={isExpanded || isDesktop}>
                <Stack spacing={{ xs: 3, sm: 4, lg: 6 }}>
                  {enrollmentSteps.enrollmentSteps.map((step, index) => (
                    <EnrollmentStepsSection
                      key={index}
                      number={index + 1}
                      title={step.title}
                      description={step.description}
                      isDesktop={isDesktop}
                    />
                  ))}
                </Stack>
              </Collapse>
            </Box>

            {isDesktop && (
              <Box
                component="a"
                href={retailerDpnInstance.signup_link || '#'}
                target="_blank"
                rel="noopener noreferrer"
                sx={{
                  p: 2,
                  bgcolor: 'background.paper',
                  borderRadius: 1,
                  border: '1px solid',
                  borderColor: 'primary.main',
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 2,
                  mt: 8,
                  minWidth: '472px',
                  textDecoration: 'none',
                  color: 'inherit',
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    bgcolor: 'action.hover',
                    textDecoration: 'none'
                  },
                }}
              >
                <Box sx={{ width: '100%' }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ color: 'text.primary', fontSize: 18, fontWeight: 700 }}>
                      {enrollmentSteps.noClubAccountPrompt}
                    </Typography>
                    <ArrowUpRightIcon sx={{ color: 'text.primary' }} />
                  </Box>
                  <Typography sx={{ color: 'text.primary', fontSize: 18 }}>
                    {enrollmentSteps.clubSignupInstruction}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default EnrollmentStepsComponent;