import React, { useState, useEffect, useCallback, useContext } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import './FreeText.scss';
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import { SiteConfigContext } from '../SiteConfigContext';
import { getPageBySlug } from '../shared/util';
import SitePage from '../site';

const hasModalTrigger = (content) => {
  const div = document.createElement('div');
  div.innerHTML = content;
  return !!div.querySelector('[data-modal-slug]');
};

function About({ component }) {
  const siteConfig = useContext(SiteConfigContext);

  const { title, column_1, column_2, column_3 } = component;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSlug, setModalSlug] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [modalPage, setModalPage] = useState(null);

  const shouldRenderModal = 
    hasModalTrigger(column_1) || 
    hasModalTrigger(column_2) || 
    hasModalTrigger(column_3);

  const handleModalOpen = useCallback((slug) => {
    setModalSlug(slug);
    setIsModalOpen(true);
    setIsLoading(true);

    let pageObject = getPageBySlug(siteConfig, slug);
    if (!pageObject) {
      const altSlug = slug.startsWith('/') ? slug.slice(1) : `/${slug}`;
      pageObject = getPageBySlug(siteConfig, altSlug);
    }

    if (pageObject) {
      setModalPage(pageObject);
    } else {
      console.error(`No page found for slug: ${slug}`);
    }

    setIsLoading(false);
  }, [siteConfig]);

  const handleModalClose = () => {
    setIsModalOpen(false);
    setModalSlug('');
    setModalPage(null);
  };

  useEffect(() => {
    const handleClick = (event) => {
      const modalTrigger = event.target.closest('[data-modal-slug]');
      if (modalTrigger) {
        const slug = modalTrigger.getAttribute('data-modal-slug');
        handleModalOpen(slug);
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [handleModalOpen]);

  const renderColumns = () => {
    const columns = [];
    if (column_1) {
      columns.push(
        <div
          key="column-1"
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_1 }}
        />
      );
    }
    if (column_2) {
      columns.push(
        <div
          key="column-2"
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_2 }}
        />
      );
    }
    if (column_3) {
      columns.push(
        <div
          key="column-3"
          className="free-text-column"
          dangerouslySetInnerHTML={{ __html: column_3 }}
        />
      );
    }
    return columns.map((column, index) => (
      <div key={`column-wrapper-${index}`} className={`column column-${index + 1}`}>
        {column}
      </div>
    ));
  };

  if (!shouldRenderComponent(component.auth_visibility)) {
    return null;
  }

  return (
    <>
      <style>
        {`
          @media screen and (max-width: 768px) {
            .customButton {
              width: 100% !important;
              margin-bottom: -12px;
              padding: 12px 24px !important;
              font-size: 14px !important;
            }
          }
        `}
      </style>
      <div
        className="free-text-block"
        style={{
          margin: `${component.margin}px`,
          padding: `${component.padding}px`,
          width: `${component.width}%`,
        }}
      >
        {title && <h2 className="free-text-title">{title}</h2>}
        <div className="columns-container">{renderColumns()}</div>
      </div>
      {shouldRenderModal && (
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: {
              xs:'12px',
              md:'24px',
            },
            width: {
              xs: '365px',
              sm: '600px',
              md: '800px'
            },
            maxHeight: {
              xs: '90vh',
            },
            bgcolor: 'white',
            boxShadow: 24,
            overflow: 'auto',
            zIndex: 1000,
            scrollbarWidth: 'none',
          }}>
            <IconButton
              aria-label="close"
              onClick={handleModalClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 4, 
                color: (theme) => theme.palette.grey[500],
                '&:focus': { outline: 'none' },
              }}
            >
              <CloseIcon />
            </IconButton>
            {isLoading ? (
              <CircularProgress />
            ) : modalPage ? (
              <div style={{ width: '100%', height: '100%', overflow: 'auto', paddingTop: '20px' }}>
                <SitePage page={modalPage} />
              </div>
            ) : (
              <div>No content available for this modal.</div>
            )}
          </Box>
        </Modal>
      )}
    </>
  );
}

export default About;