import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { formStyles } from "../styles/cpg/form_styles";
import { forgotPassword } from "../service/account_service";
import { validateEmailAddress } from "../shared/util/UserValidations";
import { getRoutePrefix } from "../config/retailerConfig";
import { getFullPath } from "../config/retailerConfig";

function ForgotWrapper(props) {
  const forgotConfig = props.page.components.find(
    (component) => component.block_type === "ForgotBlock"
  );
  const RESET_PASSWORD_PATH = "/resetpassword/";

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [validations, setValidations] = useState({
    error: "",
    isLoading: false,
    successMessage: "",
  });
  const useStyles = makeStyles((theme) => ({
    paper: {
      maxWidth: 564,
      margin: `${theme.spacing(1)}px auto`,
      padding: theme.spacing(5),
      boxShadow: "none",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    loginPage: {
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    loginLogo: {
      width: "240px",
      height: "auto",
      margin: "1rem 4rem",
      objectFit: "contain",

      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
    },
    signIntxt: {
      fontSize: "32px",
      fontFamily: "InterMedium",
      fontWeight: "900",
    },
    createAccount: {
      padding: "10px",
      fontSize: "16px",
      fontFamily: "InterMedium",
    },
    createTxt: {
      color: props.page.color_background,
      cursor: "pointer",
      fontFamily: "InterMedium",
    },
    subText: {
      padding: "10px",
      fontSize: "14px",
      fontFamily: "InterMedium",
    },
    forgotTxt: {
      fontSize: "16px",
      padding: "15px",
      fontWeight: " 700",
      color: props.page.color_background,
      cursor: "pointer",
    },
    alertBoxsuccess: {
      color: " #44a047",
      backgroundColor: "rgba(93, 157, 81, 0.2)",
      border: "1px solid #44a047",
      display: "flex",
      alignItems: "center",
      fontSize: "14px",
      padding: "1.4rem 0.6rem",
      borderRadius: "8px",
      width: "98%",
      margin: "0 auto",
    },
  }));

  const classes = useStyles();
  const globalClasses = formStyles(props);

  // input filed values assigning
  const handleChange = (prop) => (event) => {
    setEmail(event.target.value);
    setValidations({ ...validations, error: "", successMessage: "" });
  };

  const handleFormFieldclass = (fieldValue) => {
    let txtFiledValue;
    if (validations.error) txtFiledValue = globalClasses.errortextfield;
    else txtFiledValue = globalClasses.textField;
    return txtFiledValue;
  };

  const handleForgot = async () => {
    setValidations({ ...validations, isLoading: true });
    const validation = validateEmailAddress(email, "forgot");
    if (validation) {
      setValidations({ ...validations, error: validation, isLoading: false });
    } else {
      setValidations({ ...validations, error: "", isLoading: true });

      const linkHost = `${window.location.origin}${getRoutePrefix()}`;
      const linkPath = RESET_PASSWORD_PATH;

      const forgotRes = forgotPassword(
        email,
        props.page.dpn_instance.dpn_instance,
        linkHost,
        linkPath
      );

      forgotRes
        .then((res) => {
          setValidations({ ...validations, isLoading: false });
          if (res.data) {
            setValidations({
              ...validations,
              error: "",
              successMessage: forgotConfig.success_msg,
              isLoading: false,
            });
          }
        })
        .catch(() => {
          setValidations({
            ...validations,
            error: "",
            successMessage: forgotConfig.success_msg,
            isLoading: false,
          });
        });
    }
  };

  if (!forgotConfig) {
    return <>Loading...</>;
  }

  return (
    <div className={classes.loginPage}>
      <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
        <a
          onClick={() => navigate(getFullPath(""))}
          rel="noreferrer"
        >
          <img
            tabIndex={0}
            className={classes.loginLogo}
            alt="logo"
            src={forgotConfig.logo}
          />
        </a>
      </Grid>

      <Paper className={classes.paper}>
        <Grid container wrap="nowrap" spacing={2}>
          <Grid item xs={12} zeroMinWidth style={{ textAlign: "center" }}>
            <Typography
              tabIndex={0}
              className={classes.signIntxt}
              noWrap
              data-testid="signIn"
            >
              {forgotConfig.page_title}
            </Typography>

            <Typography
              className={classes.createAccount}
              data-testid="donthaveAccount"
            >
              Enter your email below and we'll send you an email with the
              necessary steps to reset your password.
            </Typography>

            <Grid item xs={12}>
              <FormControl
                className={globalClasses.formInputControl}
                variant="outlined"
              >
                <label htmlFor="Email">
                  <FormHelperText
                    className={globalClasses.inputLabel}
                    id="outlined-weight-helper-text"
                  >
                    Email{" "}
                    <span className={globalClasses.mandatoryField}>*</span>
                  </FormHelperText>
                </label>

                <TextField
                  id="Email"
                  variant="outlined"
                  error={validations.error !== ""}
                  value={email}
                  onChange={handleChange()}
                  className={handleFormFieldclass("errorUsername")}
                />
              </FormControl>
              {validations.error !== "" && (
                <FormHelperText
                  tabIndex={0}
                  className={globalClasses.errorMessage}
                  id="outlined-weight-helper-text"
                >
                  {validations.error}
                </FormHelperText>
              )}

              {validations.successMessage !== "" &&
                validations.successMessage !== undefined && (
                  <div className={classes.alertBoxsuccess}>
                    {forgotConfig.success_msg}
                  </div>
                )}

              <Button
                variant="contained"
                onClick={handleForgot}
                disabled={validations.isLoading}
                className={globalClasses.submitBtn}
              >
                <div data-testid="signBtn" className="signBtn">
                  {forgotConfig.submit_btn_text}
                </div>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

export default ForgotWrapper;
