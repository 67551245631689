import React, { useState  } from 'react';
import './stateGate.scss';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import statesList from './stateList';
import { Select, MenuItem, makeStyles } from "@material-ui/core";

function StateGate({page, stateGate, updateStateGate, title, instructions, selectedState, setSelectedState, invalidStates }) {

    const block = page.components.find(component => component.block_type === 'StateGateBlock');

    const handleChange = (event) => {
        setSelectedState(event.target.value);
    };

    const ColoredArrowDownIcon = ({ color }) => (
        <KeyboardArrowDownIcon className={classes.downArrowIcon} style={{ color }} />
    );

    const useStyles = makeStyles(() => ({
        downArrowIcon: {
            position: 'relative',
            bottom: '6px',
            right: '20px',
            pointerEvents: 'none',
            cursor: 'pointer'
        },
        selectRoot: {
            '&:focus': {
              backgroundColor: 'unset', 
            },
        },
        select:{
            paddingLeft: '1.5em',
            width: 'auto',
            fontFamily: 'Inter',
            fontWeight: 700,
            fontSize: '2em',
        },
        stateInputs: {
            '& .MuiInputBase-root': {
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: '8px',
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                borderBottom: 'unset',
            },
        }
    }));

    const classes = useStyles();

    return((
        <div className={`state-gate-container`}>
            <div className={`state-gate`}>
                <h2>{title}</h2>
                <div className="select-container">
                    <div className={`state-inputs ${classes.stateInputs}`}>
                        <Select
                            displayEmpty
                            renderValue={(value) => (value ? statesList.find((option) => option.value === value).title : statesList[0].title)}
                            IconComponent={() => <ColoredArrowDownIcon color={invalidStates ? block.text_color : '#000'} />}
                            value={selectedState}
                            onChange={handleChange}
                            classes={{
                                root: classes.selectRoot,
                                select: classes.select,
                            }}
                            className={`${classes.selectRoot} select-dropdown`}
                            style={{
                                color: invalidStates ? block.text_color : '#000',
                                borderBottom: `2px solid ${block.border_color}`,
                            }}
                            inputProps={{
                                'aria-label': 'State',
                            }}
                        >
                            {statesList.map((option, index) => (
                                <MenuItem key={option.title} value={option.value} data-index={index}>
                                    {option.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </div>
                <label>{instructions}</label>
            </div>
        </div>
    ));
}

export default StateGate